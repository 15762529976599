import CancelButton from 'components/shared/Button/CancelButton';
import PrimaryButton from 'components/shared/Button/PrimaryButton';
import { useGlobalStore } from 'global-state/useStore';
import React from 'react';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';

import { useTranslation } from 'react-i18next';

const Dialog = () => {
	const { t } = useTranslation();

	const confirmationDialog = useGlobalStore((state) => state.confirmationDialog);
	const toggleConfirmationDialog = useGlobalStore((state) => state.toggleConfirmationDialog);

	const confirmClick = () => {
		confirmationDialog?.confirmAction();
		closeModal();
	};

	const closeModal = () => {
		toggleConfirmationDialog({
			dialogOpen: false,
			confirmAction: () => null,
			headerTitle: '',
			message: '',
			type: null
		});
	};

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			open={confirmationDialog?.dialogOpen ?? false}
			onClose={closeModal}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 1000
			}}
			className="shadow-xl">
			<Fade in={confirmationDialog?.dialogOpen}>
				<Box className="absolute top-1/2 left-1/2 w-max max-w-[90%] -translate-x-1/2 -translate-y-1/2 transform overflow-y-auto bg-primary shadow-xl shadow-gray-900 outline-none">
					{confirmationDialog?.headerTitle && (
						<div className="flex w-full flex-row justify-center bg-secondary py-4 text-xl font-medium text-white">
							{confirmationDialog?.headerTitle}
						</div>
					)}
					<div className="m-4 flex flex-col md:m-6">
						<div className="w-[25rem] max-w-[90%] justify-center self-center text-center">
							{confirmationDialog?.message}
						</div>
						<div className="my-4 mt-8 flex h-9 flex-row justify-center md:justify-end">
							{confirmationDialog?.type === 'confirmation' && (
								<CancelButton
									className="mr-4 h-9"
									onClick={closeModal}
									text={t(confirmationDialog?.cancelActionLabel?.toUpperCase() ?? 'NO')}
								/>
							)}
							<PrimaryButton
								className={'ml-4 h-9'}
								onClick={confirmClick}
								text={t(confirmationDialog?.confirmActionLabel?.toUpperCase() ?? 'YES')}
							/>
						</div>
					</div>
				</Box>
			</Fade>
		</Modal>
	);
};

export default Dialog;
