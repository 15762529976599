type Vector = {
	i: number; //starting y coord on the image
	j: number; //starting x coord on the image
	di: number; //shift on the y-axis
	dj: number; //shift on the x-axis
};

export const drawWaterLine = (
	waterLineData: number[],
	ctx: CanvasRenderingContext2D,
	scaledX = 1,
	scaledY = 1
) => {
	if (waterLineData?.length < 4) return; // Check if there are less than four points

	ctx.beginPath();
	ctx.strokeStyle = 'red';
	ctx.lineWidth = 3;

	// Start at the first point

	if (waterLineData.length === 4) {
		ctx.moveTo(waterLineData[0] / scaledX, waterLineData[1] / scaledY);
		// If there are exactly four points, draw a line to the second point
		ctx.lineTo(waterLineData[2] / scaledX, waterLineData[3] / scaledY);
	} else {
		ctx.moveTo(waterLineData[1] / scaledX, waterLineData[0] / scaledY);
		// If there are more than four points, iterate through them
		for (let i = 2; i < waterLineData.length; i += 2) {
			ctx.lineTo(waterLineData[i + 1] / scaledX, waterLineData[i] / scaledY);
		}
	}

	ctx.stroke();
	ctx.closePath();
};

export const convertDataToVectors = (displacementField: any) => {
	const vectorData: Vector[] = [];
	for (let index = 0; index < displacementField[0]?.length; index++) {
		vectorData.push({
			i: displacementField[0][index],
			j: displacementField[1][index],
			di: displacementField[2][index],
			dj: displacementField[3][index]
		});
	}
	return vectorData;
};

export const drawVectors = (
	vectorData: Vector[],
	ctx: CanvasRenderingContext2D,
	scaledX = 1,
	scaledY = 1,
	isStiv = false
) => {
	vectorData.forEach((vector) => {
		drawLine(vector, ctx, scaledX, scaledY, isStiv);
		drawTriangle(vector, ctx, scaledX, scaledY, isStiv);
	});
};

export const drawLine = (
	vector: Vector,
	ctx: CanvasRenderingContext2D,
	scaledX = 1,
	scaledY = 1,
	isSTIV = false // New parameter
) => {
	ctx.beginPath();
	ctx.lineWidth = isSTIV ? 2 : 1; // Increase line width if isSTIV is true
	ctx.fillStyle = 'black';
	ctx.strokeStyle = 'black';
	const lengthMultiplier = isSTIV ? 24 : 6; // Increase length if isSTIV is true
	ctx.moveTo(vector.j / scaledX, vector.i / scaledY);
	ctx.lineTo(
		(vector.j + lengthMultiplier * vector.dj) / scaledX,
		(vector.i + lengthMultiplier * vector.di) / scaledY
	);
	ctx.stroke();
};

export const drawTriangle = (
	vector: Vector,
	ctx: CanvasRenderingContext2D,
	scaledX = 1,
	scaledY = 1,
	isSTIV = false // New parameter
) => {
	ctx.beginPath();
	ctx.lineWidth = isSTIV ? 2 : 1; // Increase line width if isSTIV is true
	ctx.fillStyle = 'black';
	ctx.strokeStyle = 'black';
	const arrowSize = isSTIV ? 16 : 4; // Adjust for larger arrowhead if isSTIV is true
	const lengthMultiplier = isSTIV ? 24 : 6; // Increase length if isSTIV is true
	const heightMultiplier = isSTIV ? 3 : 1; // Increase height if isSTIV is true
	const arrowBeginCoords = {
		x: vector.j + arrowSize * vector.dj,
		y: vector.i + arrowSize * vector.di
	};
	ctx.moveTo(arrowBeginCoords.x / scaledX, arrowBeginCoords.y / scaledY);
	ctx.lineTo(
		(arrowBeginCoords.x + heightMultiplier * vector.di) / scaledX,
		(arrowBeginCoords.y - heightMultiplier * vector.dj) / scaledY
	);
	ctx.lineTo(
		(vector.j + lengthMultiplier * vector.dj) / scaledX,
		(vector.i + lengthMultiplier * vector.di) / scaledY
	);
	ctx.lineTo(
		(arrowBeginCoords.x - heightMultiplier * vector.di) / scaledX,
		(arrowBeginCoords.y + heightMultiplier * vector.dj) / scaledY
	);
	ctx.lineTo(arrowBeginCoords.x / scaledX, arrowBeginCoords.y / scaledY);
	ctx.fill();
	ctx.stroke();
};
