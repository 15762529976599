import { AddCircleOutline, DeleteOutline } from '@mui/icons-material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import DateTimeFilterComponent from 'components/Measurements/MeasurementsFilter/DateTimeFilter';
import NumericFilterComponent from 'components/Measurements/MeasurementsFilter/NumericFilter';
import StringFilter from 'components/Measurements/MeasurementsFilter/StringFilter';
import Modal from 'components/shared/Modal/Modal';
import IconWithTooltip from 'components/shared/Tooltip/IconWithTooltip';
import { useGlobalStore } from 'global-state/useStore';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { parseMeasurementFilters } from 'utils/functions';
import {
	MeasurementFilter,
	MeasurementFilterType,
	measurementsFilterTypes
} from 'utils/helperData';

const AddFilterModal = () => {
	const { t } = useTranslation();

	const selectedMeasurementFilters = useGlobalStore((state) => state.selectedMeasurementFilters);
	const setSelectedMeasurementFilter = useGlobalStore(
		(state) => state.setSelectedMeasurementFilter
	);
	const toggleMeasurementsFilterModal = useGlobalStore(
		(state) => state.toggleMeasurementsFilterModal
	);
	const appliedMeasurementFilters = useGlobalStore((state) => state.appliedMeasurementFilters);
	const setAppliedMeasurementFilter = useGlobalStore((state) => state.setAppliedMeasurementFilter);
	const setParsedFilters = useGlobalStore((state) => state.setParsedFilters);

	const [filtersState, setFiltersState] = useState(selectedMeasurementFilters);

	useEffect(() => {
		setFiltersState(selectedMeasurementFilters);
	}, [selectedMeasurementFilters]);

	const handleFilterChange = (selectedFilterName: string, fieldIndex: number) => {
		const selectedFilter = measurementsFilterTypes.find(
			(filter) => filter.filterName === selectedFilterName
		);

		const filters = [...filtersState];
		filters[fieldIndex] = selectedFilter ?? {
			filterName: '',
			filterType: '',
			operator: { label: '', operator: '', value: '' },
			filterValue: ''
		};

		filters && setFiltersState(filters);
	};

	const handleAddFilter = () => {
		setFiltersState([
			...filtersState,
			{
				filterName: '',
				filterType: '',
				operator: { label: '', operator: '', value: '' },
				filterValue: ''
			}
		]);
	};
	const handleRemoveFilter = (fieldIndex: number) => {
		const newArray = [...filtersState];
		newArray.splice(fieldIndex, 1);

		setFiltersState(
			newArray.length === 0
				? [
						{
							filterName: '',
							filterType: '',
							operator: { label: '', operator: '', value: '' },
							filterValue: ''
						}
				  ]
				: newArray
		);
	};

	const handleApplyFilters = () => {
		setParsedFilters(parseMeasurementFilters(filtersState));
		setAppliedMeasurementFilter(filtersState);
		setSelectedMeasurementFilter(filtersState);

		toggleMeasurementsFilterModal();
	};

	const applyFiltersDisabled = () => {
		return filtersState.some(
			(filter) =>
				(filter.operator.value === '' && filter.filterValue === '') || filter.filterValue === ''
		);
	};

	const filterTypeRender = (
		filterType: MeasurementFilterType,
		fieldIndex: number,
		filter: MeasurementFilter
	) => {
		switch (filterType) {
			case 'string':
				return (
					<>
						<StringFilter filterFieldNumber={fieldIndex} selectedFilter={filter} />
					</>
				);
			case 'number':
				return (
					<>
						<NumericFilterComponent filterFieldNumber={fieldIndex} selectedFilter={filter} />
					</>
				);
			case 'date':
				return (
					<>
						<DateTimeFilterComponent filterFieldNumber={fieldIndex} selectedFilter={filter} />
					</>
				);
			default:
				return <></>;
		}
	};

	return (
		<>
			<Modal
				open={true}
				closeModal={() => {
					toggleMeasurementsFilterModal();
					setSelectedMeasurementFilter(appliedMeasurementFilters);
				}}
				modalTitle={'Add filter modal'}>
				<div>
					<div className={'flex justify-end p-1'}>
						<IconWithTooltip
							title={t('ADD_NEW')}
							icon={<AddCircleOutline onClick={handleAddFilter} />}
						/>
					</div>
					<div className={'flex w-full justify-between p-2'}>
						<div className={'flex w-[50rem] flex-1 flex-col gap-4 max-md:gap-12'}>
							{filtersState.map((filter, fieldIndex) => {
								return (
									<div
										className={'flex justify-evenly '}
										key={`${filter.filterName}-${fieldIndex}`}>
										<div className={'flex flex-1 flex-wrap gap-4'}>
											<FormControl required size={'small'}>
												<InputLabel id="demo-simple-select-required-label">
													{t('FILTER_TYPE')}
												</InputLabel>
												<Select
													className={'w-52'}
													label={t('FILTER_TYPE')}
													value={filtersState[fieldIndex].filterName}
													onChange={(e) =>
														handleFilterChange(e.target.value as string, fieldIndex)
													}>
													{measurementsFilterTypes.map((measurementFilter) => {
														return (
															<MenuItem
																key={`measurement-filter-${measurementFilter.filterName}`}
																value={measurementFilter.filterName}>
																<div className={'capitalize'}>
																	{t(measurementFilter.filterName).replaceAll('_', ' ')}
																</div>
															</MenuItem>
														);
													})}
												</Select>
											</FormControl>
											<div>{filterTypeRender(filter.filterType, fieldIndex, filter)}</div>
										</div>
										<div className={' cursor-pointer items-center align-middle text-danger'}>
											<DeleteOutline onClick={() => handleRemoveFilter(fieldIndex)} />
										</div>
									</div>
								);
							})}
						</div>
					</div>
					<div className={'my-4 flex justify-center'}>
						<Button
							variant={'contained'}
							className={'bg-accent normal-case disabled:bg-gray-500 disabled:text-primary'}
							disabled={applyFiltersDisabled()}
							onClick={() => handleApplyFilters()}>
							{t('APPLY_FILTERS')}
						</Button>
					</div>
				</div>
			</Modal>
		</>
	);
};
export default AddFilterModal;
