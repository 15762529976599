import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { Divider, List, ListItem, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CancelButton from 'components/shared/Button/CancelButton';
import Modal from 'components/shared/Modal/Modal';
import { IModalProps } from 'components/shared/Modal/types';
import IconWithTooltip from 'components/shared/Tooltip/IconWithTooltip';
import { useGlobalStore } from 'global-state/useStore';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalSearch } from 'rq/hooks/searchHook';
import { useSitesPaginated } from 'rq/hooks/sitesHook';
import useDebounce from 'rq/hooks/useDebounce';

const GlobalSearchModal: FunctionComponent<IModalProps> = ({ closeModal, open }) => {
	const { t } = useTranslation();

	const globalSearchToggled = useGlobalStore((state) => state.globalSearchToggled);
	const toggleGlobalSearchBar = useGlobalStore((state) => state.toggleGlobalSearchBar);
	const toggleOrganizationSwitchModal = useGlobalStore(
		(state) => state.toggleOrganizationSwitchModal
	);

	const [filter, setFilter] = useState('');

	const debouncedSearchQuery = useDebounce(filter);

	const { data: sites, isFetching: sitesFetching } = useSitesPaginated(
		{
			queryParams: { search: debouncedSearchQuery, page: 1, page_size: 10 }
		},
		{ keepPreviousData: true, enabled: globalSearchToggled.searchType === 'in_organization' }
	);
	const { data: globalSites, isFetching: globalSitesFetching } = useGlobalSearch(
		{
			searchTerm: debouncedSearchQuery,
			entity: 'site'
		},
		{ enabled: debouncedSearchQuery.length > 2 && globalSearchToggled.searchType === 'everywhere' }
	);

	const handleSiteFromOtherOrgClick = (organizations: any) => {
		toggleGlobalSearchBar({ toggled: false, searchType: null });
		toggleOrganizationSwitchModal({ organizations: organizations, toggled: true });
	};

	return (
		<Modal open={open} closeModal={closeModal} modalTitle={''}>
			<div className={'flex flex-col items-center justify-center'}>
				<div className={'flex flex-row items-center justify-between'}>
					<TextField
						placeholder={t(
							globalSearchToggled.searchType === 'in_organization'
								? 'SEARCH_SITES'
								: 'SEARCH_EVERYWHERE'
						)}
						className={'mr-1'}
						onChange={(e) => setFilter(e.target.value)}></TextField>{' '}
					<IconWithTooltip
						title={t('SEARCH_EVERYWHERE')}
						icon={
							<TravelExploreIcon
								onClick={() => {
									toggleGlobalSearchBar({
										searchType:
											globalSearchToggled.searchType !== 'everywhere'
												? 'everywhere'
												: 'in_organization',
										toggled: true
									});
								}}
								className={globalSearchToggled.searchType === 'everywhere' ? 'text-customred' : ''}
							/>
						}
					/>
				</div>
				<div className={'mt-4 flex w-full flex-col overflow-scroll md:h-96'}>
					{sitesFetching || globalSitesFetching ? (
						<div className={'flex w-full justify-center'}>
							<CircularProgress />
						</div>
					) : (
						<List>
							{globalSearchToggled.searchType === 'in_organization' &&
								sites?.results?.map((site) => {
									return (
										<>
											<ListItem key={site.site_id} className={'w-full cursor-pointer'}>
												{site.name}
											</ListItem>
											<Divider />
										</>
									);
								})}
							{globalSearchToggled.searchType === 'everywhere' &&
								globalSites?.site.results?.map((option) => {
									return (
										<>
											<ListItem
												key={option.name}
												className={'cursor-pointer'}
												onClick={() => handleSiteFromOtherOrgClick(option.organizations)}>
												<div>
													{option.name}
													{globalSearchToggled.searchType === 'everywhere' && (
														<div className={' flex flex-wrap text-xs font-bold '}>
															<>{t('ORGANIZATIONS')}:</>
															{option.organizations?.map((e, index: number) => {
																return (
																	<span key={e.name} className={'ml-1 font-normal '}>
																		{e.name}
																		{index !== option.organizations.length - 1 && ','}
																	</span>
																);
															})}
														</div>
													)}
												</div>
											</ListItem>
											<Divider />
										</>
									);
								})}
						</List>
					)}
				</div>
				<CancelButton onClick={closeModal} text={t('CANCEL')} />
			</div>
		</Modal>
	);
};
export default GlobalSearchModal;
