import { QueryClient } from 'react-query';

export function generateQueryClient(): QueryClient {
	return new QueryClient({
		defaultOptions: {
			queries: {
				staleTime: 600000,
				cacheTime: 900000,
				refetchOnMount: true,
				refetchOnWindowFocus: false,
				refetchOnReconnect: false
			}
		}
	});
}

export const queryClient = generateQueryClient();
