import React, { Suspense } from 'react';

import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import CircularLoadingFullHeight from '../components/shared/LoadingIndicators/CircularLoadingFullHeight';

const RegisterPage = React.lazy(() => import('../pages/Auth/RegisterPage'));
const ResetPassword = React.lazy(() => import('../pages/Auth/ResetPassword'));
const ResetPasswordConfirm = React.lazy(() => import('../pages/Auth/ResetPasswordConfirm'));
const AuthPagesTheme = React.lazy(() => import('../pages/Auth/AuthPagesTheme'));
const LoginPage = React.lazy(() => import('../pages/Auth/LoginPage'));

const PublicRouter = () => {
	return (
		<div className="h-screen w-screen">
			<Routes>
				<Route
					element={
						<Suspense fallback={<CircularLoadingFullHeight />}>
							<AuthPagesTheme>
								<Outlet />
							</AuthPagesTheme>
						</Suspense>
					}>
					<Route
						path={'/login'}
						element={
							<Suspense fallback={<CircularLoadingFullHeight />}>
								<LoginPage />
							</Suspense>
						}
					/>
					<Route
						path="/password/reset"
						element={
							<Suspense fallback={<CircularLoadingFullHeight />}>
								<ResetPassword />
							</Suspense>
						}
					/>
					<Route
						path="/register"
						element={
							<Suspense fallback={<CircularLoadingFullHeight />}>
								<RegisterPage />
							</Suspense>
						}
					/>
					<Route
						path="/password/reset/confirm/:uid/:token"
						element={
							<Suspense fallback={<CircularLoadingFullHeight />}>
								<ResetPasswordConfirm />
							</Suspense>
						}
					/>
					<Route path={'*'} element={<Navigate to="/login" replace />} />
				</Route>
			</Routes>
		</div>
	);
};

export default PublicRouter;
