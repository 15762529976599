import { Autocomplete, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import CancelButton from 'components/shared/Button/CancelButton';
import PrimaryButton from 'components/shared/Button/PrimaryButton';
import Modal from 'components/shared/Modal/Modal';
import { IModalProps } from 'components/shared/Modal/types';
import { useGlobalStore } from 'global-state/useStore';
import { OrganizationListRetrieve } from 'interfaces/models/OrganizationListRetrieve';
import React, { FunctionComponent, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { queryKeys } from 'rq/constants';
import { useOrganizations } from 'rq/hooks/organizationsHook';
import { useMoveSite, useRemoveAssociation } from 'rq/hooks/sitesHook';
import { queryClient } from 'rq/queryClient';

const MoveOrShareSiteModal: FunctionComponent<IModalProps> = ({ open, closeModal }) => {
	const { t } = useTranslation();

	const currentOrganization = useGlobalStore((state) => state.currentOrganization);
	const moveOrShareSiteModal = useGlobalStore((state) => state.moveOrShareSiteModal);
	const toggleMoveOrShareSiteModal = useGlobalStore((state) => state.toggleMoveOrShareSiteModal);

	const { data: organizations, isLoading: loadingOrganizations } = useOrganizations();

	const { mutate: moveSite } = useMoveSite();
	const { mutate: removeAssociation } = useRemoveAssociation();

	const [selectedOrganization, setSelectedOrganization] = useState<OrganizationListRetrieve | null>(
		null
	);

	const [inputValue, setInputValue] = React.useState('');

	const [selectedAction, setSelectedAction] = useState<'move' | 'share' | string>('move');

	const handleMoveOrShare = () => {
		if (selectedAction === 'move' && selectedOrganization) {
			moveSite(
				{
					site_id: moveOrShareSiteModal.selectedSiteId as number,
					organization_id: selectedOrganization.id as number
				},
				{
					onSuccess: () => {
						return removeAssociation(
							{
								site_id: moveOrShareSiteModal.selectedSiteId as number,
								organization_id: currentOrganization.id as number
							},
							{
								onSuccess: () => {
									queryClient.invalidateQueries(queryKeys.sites_paginated);
									toast.success(t('SITE_MOVED'));
									toggleMoveOrShareSiteModal();
								}
							}
						);
					}
				}
			);
		} else if (selectedAction === 'share' && selectedOrganization) {
			moveSite(
				{
					site_id: moveOrShareSiteModal.selectedSiteId as number,
					organization_id: selectedOrganization.id as number
				},
				{
					onSuccess: () => {
						toggleMoveOrShareSiteModal();
						toast.success(t('SITE_SHARED'));
					}
				}
			);
		}
	};

	return (
		<div>
			<Modal open={open} closeModal={closeModal} modalTitle={t('MOVE_OR_SHARE_SITE')}>
				<div className={'flex flex-col p-5'}>
					{loadingOrganizations ? (
						<div className={'flex items-center justify-center'}>
							<CircularProgress />
						</div>
					) : (
						<>
							<FormControl>
								<Autocomplete
									className={'mt-2 w-auto'}
									options={organizations ?? []}
									getOptionLabel={(option) => option.name}
									value={selectedOrganization}
									onChange={(event: any, newValue: OrganizationListRetrieve | null) => {
										setSelectedOrganization(newValue);
									}}
									inputValue={inputValue}
									onInputChange={(event, newInputValue) => {
										setInputValue(newInputValue);
									}}
									renderInput={(params) => (
										<TextField {...params} label={t('SELECT_ORGANIZATION')} />
									)}
								/>
							</FormControl>
							<FormControl>
								<RadioGroup
									row
									value={selectedAction}
									onChange={(e) => setSelectedAction(e.target.value)}>
									<FormControlLabel value="move" control={<Radio />} label={t('MOVE')} />
									<FormControlLabel value="share" control={<Radio />} label={t('SHARE')} />
								</RadioGroup>
							</FormControl>
							<div className={'h-20'}>
								{selectedAction === 'move'
									? t('MOVE_SITE_DESCRIPTION', { currentOrg: currentOrganization.name })
									: t('SHARE_SITE_DESCRIPTION')}
							</div>
							<div className={'flex justify-end gap-2'}>
								<CancelButton onClick={closeModal} text={t('CANCEL')} />
								<PrimaryButton
									onClick={handleMoveOrShare}
									text={selectedAction === 'move' ? t('MOVE') : t('SHARE')}
								/>
							</div>
						</>
					)}
				</div>
			</Modal>
		</div>
	);
};
export default MoveOrShareSiteModal;
