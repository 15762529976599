import Button from '@mui/material/Button';
import Modal from 'components/shared/Modal/Modal';
import { useGlobalStore } from 'global-state/useStore';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { queryKeys } from 'rq/constants';
import { useSite } from 'rq/hooks/sitesHook';
import { queryClient } from 'rq/queryClient';
import SitesService from 'services/SitesService/SitesService';

const SiteImageModal = () => {
	const { t } = useTranslation();
	const siteImageModalOpen = useGlobalStore((state) => state.siteImageModalOpen);
	const toggleSiteImageModal = useGlobalStore((state) => state.toggleSiteImageModal);
	const selectedSiteId = useGlobalStore((state) => state.selectedSiteId);

	const fileInput = useRef<HTMLInputElement | null>(null);
	const { data: siteDetails } = useSite({ site_id: Number(selectedSiteId) });

	const [selectedFile, setSelectedFile] = useState<File | undefined>();

	const [preview, setPreview] = useState<string | null>();

	useEffect(() => {
		if (selectedFile) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setPreview(reader.result as string);
			};
			reader.readAsDataURL(selectedFile);
		} else {
			setPreview(null);
		}
	}, [selectedFile]);
	const fileSelectHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputFile = event.target as HTMLInputElement;
		if (inputFile) {
			if (!inputFile.files?.length) {
				return;
			}
			setSelectedFile(inputFile?.files[0]);
		}
	};
	const onFileUpload = () => {
		SitesService.updateSiteImage(
			new File([selectedFile as BlobPart], 'site-image.png'),
			Number(selectedSiteId)
		).then(() => {
			toast.success(t('IMAGE_UPDATED'));
			toggleSiteImageModal();
			queryClient.invalidateQueries([queryKeys.sites, queryKeys.site, Number(selectedSiteId)]);
			queryClient.invalidateQueries(queryKeys.sites_paginated);
		});
	};

	return (
		<Modal
			open={siteImageModalOpen}
			closeModal={toggleSiteImageModal}
			modalTitle={t('CHANGE_IMAGE')}>
			<input
				style={{ display: 'none' }}
				type="file"
				onChange={fileSelectHandler}
				accept="image/*"
				ref={fileInput}
			/>
			<div className={'flex w-full flex-col justify-center gap-4'}>
				<div className={'h-80'}>
					<img
						src={preview ? preview : siteDetails?.image}
						className={'h-full w-full object-cover'}
						alt={t('SITE_IMAGE')}></img>
				</div>
				<div className={'flex justify-center gap-2'}>
					<Button
						variant={'contained'}
						className={' w-68 normal-case'}
						onClick={() => {
							fileInput.current?.click();
						}}>
						{t('SELECT_A_PICTURE_FROM_YOUR_COMPUTER')}
					</Button>
				</div>

				<div className={'flex justify-center pb-4'}>
					<Button
						variant={'contained'}
						className={'bg-accent normal-case disabled:bg-gray-400'}
						onClick={onFileUpload}
						disabled={!selectedFile}>
						{t('UPDATE')}
					</Button>
				</div>
			</div>
		</Modal>
	);
};
export default SiteImageModal;
