import { useMutation, useQuery } from 'react-query';
import { queryClient } from 'rq/queryClient';
import SitesService from 'services/SitesService/SitesService';
import AuthService from '../../services/AuthService/AuthService';
import { UserListRetrieve } from '../interfaces/User';
import { queryKeys } from '../constants';
import { LoginData } from 'services/AuthService/interface';
import { useGlobalStore } from 'global-state/useStore';
import { checkOrgIdAndPermissions } from 'utils/functions';

export const useAuth = () => {
	return useQuery<UserListRetrieve | null>(queryKeys.me, () => AuthService.getAuth(), {
		onSuccess: (user) => {
			if (!user) return;
			const { id, permissions, name } = checkOrgIdAndPermissions(user, user?.id);
			useGlobalStore.setState({
				currentOrganization: {
					id: id,
					permissions: permissions,
					name: name
				}
			});
		}
	});
};

export const useLogin = () => {
	return useMutation((data: LoginData) => AuthService.login(data), {
		onSuccess: () => {
			SitesService.getLastMeasurements({ last_measurements: false, sub: false }).then((data) => {
				queryClient.setQueryData(queryKeys.last_measurements, data);
			});
		}
	});
};

export const useLogout = () => {
	return useMutation(() => AuthService.logout());
};
