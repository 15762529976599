import { DischargeKeeperStationDetails } from 'interfaces/models/DischargeKeeperStationDetails';
import { DischargeStationDetails } from 'interfaces/models/DischargeStationDetails';
import toast from 'react-hot-toast';
import i18n from 'res/localization/i18n';
import { scientificNotationNumberPattern } from 'utils/regex';

export const validateFileInput = ({
	inputAlias,
	profileInputFileType,
	isProfile3D,
	fileContent
}: {
	inputAlias: string;
	profileInputFileType: any;
	fileContent: string;
	isProfile3D: boolean;
}) => {
	const requiredNumberValues = profileInputFileType.numberOfValuesInFile;
	const minNumberValues = profileInputFileType.minNumberOfValuesInFile;
	const maxNumberValues = profileInputFileType.maxNumberOfValuesInFile;
	const divisableNumberValues = profileInputFileType.divisableNumberOfValuesInFile;

	let requiredNumberOfValuesMsgPart = '';
	let listOfFileValues;
	if (fileContent) listOfFileValues = fileContent.match(scientificNotationNumberPattern);

	if (inputAlias === 'profile') {
		requiredNumberOfValuesMsgPart = isProfile3D ? 9 + '+' : 6 + '+';
	} else {
		requiredNumberOfValuesMsgPart = requiredNumberValues?.toString();
	}

	let inputValid = false;
	let fileFormatErrorMessage =
		i18n.t('INVALID_FORMAT') +
		'. ' +
		i18n.t('REQUIRED_NUMBER_OF_VALUES') +
		': ' +
		requiredNumberOfValuesMsgPart +
		'.';

	if (inputAlias === 'profile') {
		if (isProfile3D) {
			if (!listOfFileValues || listOfFileValues.length < minNumberValues) {
				inputValid = false;
			} else if (listOfFileValues.length % 3 !== 0) {
				fileFormatErrorMessage =
					i18n.t('INVALID_FORMAT') + '. ' + i18n.t('VALUES_DIVISIBLE_BY') + ' ' + 3 + '.';
				inputValid = false;
			} else {
				inputValid = true;
			}
		} else {
			if (!listOfFileValues || listOfFileValues.length < minNumberValues) {
				inputValid = false;
			} else if (listOfFileValues.length % 2 !== 0) {
				fileFormatErrorMessage =
					i18n.t('INVALID_FORMAT') + '. ' + i18n.t('VALUES_DIVISIBLE_BY') + ' ' + 2 + '.';
				inputValid = false;
			} else {
				inputValid = true;
			}
		}
	} else if (listOfFileValues) {
		if (requiredNumberValues && requiredNumberValues !== listOfFileValues.length) {
			inputValid = false;
		} else if (minNumberValues && minNumberValues > listOfFileValues.length) {
			fileFormatErrorMessage =
				i18n.t('INVALID_FORMAT') +
				'. ' +
				i18n.t('MIN_NUMBER_OF_VALUES') +
				': ' +
				minNumberValues +
				'.';
			inputValid = false;
		} else if (maxNumberValues && maxNumberValues < listOfFileValues.length) {
			fileFormatErrorMessage =
				i18n.t('INVALID_FORMAT') +
				'. ' +
				i18n.t('MAX_NUMBER_OF_VALUES') +
				': ' +
				maxNumberValues +
				'.';
			inputValid = false;
		} else if (divisableNumberValues && listOfFileValues.length % divisableNumberValues !== 0) {
			fileFormatErrorMessage =
				i18n.t('INVALID_FORMAT') +
				'. ' +
				i18n.t('VALUES_DIVISIBLE_BY') +
				' ' +
				divisableNumberValues +
				'.';
			inputValid = false;
		} else {
			inputValid = true;
		}
	} else {
		inputValid = true;
	}

	return { inputValid: inputValid, errMsg: fileFormatErrorMessage };
};

export const calculateGroundGraphData = (data: { x: number | null; y: number | null }[]) => {
	let yMin = Number.MAX_VALUE,
		yMax = Number.MIN_VALUE;
	type DataPoint = { x: number | null; y: number | null; z: number | null };

	const groundChart: DataPoint[] = [];

	data.forEach((point) => {
		if (point.y === null) return;
		if (point.y < yMin) {
			yMin = point.y;
		}

		if (point.y > yMax) {
			yMax = point.y;
		}
	});

	data.forEach((point) => {
		groundChart?.push({ x: point.x, y: yMin - (yMax - yMin) * 0.1, z: point.y });
	});

	return groundChart;
};

export const prepareFreeProfileDataForVisualization = (params: number[]) => {
	if (params?.length === 0 || !params) return;
	const data: { x: number | null; y: number | null }[] = [];
	const length = params.length;

	params?.forEach((value, index) => {
		const paramIndex = index % (length / 2);
		if (index < length / 2) {
			if (!data[paramIndex]) {
				data[paramIndex] = { x: null, y: null };
			}

			data[paramIndex]['x'] = value;
		} else {
			data[paramIndex]['y'] = value;
		}
	});
	return data;
};

export const downloadCrossSection = (
	stationData: DischargeStationDetails | DischargeKeeperStationDetails
) => {
	if (stationData && stationData.profile && stationData.profile.free_params) {
		const midpoint = stationData.profile.free_params.length / 2;
		const yValues = stationData.profile.free_params.slice(0, midpoint);
		const zValues = stationData.profile.free_params.slice(midpoint);

		const maxLengthY = Math.max(...yValues.map((value) => value.toString().length));

		let textContent = 'data:text/plain;charset=utf-8,';

		for (let i = 0; i < yValues.length; i++) {
			const paddedY = yValues[i].toString().padEnd(maxLengthY + 2, ' '); // Adding 2 for extra spacing
			textContent += paddedY + zValues[i] + '\n';
		}

		const encodedUri = encodeURI(textContent);
		const link = document.createElement('a');
		link.setAttribute('href', encodedUri);
		link.setAttribute(
			'download',
			stationData.station_type + ' station ' + stationData.station_id + ' cross-section.txt'
		);
		document.body.appendChild(link);
		link.click();
	}
};

export const prepareData = (data: string) => {
	let replaced = data
		.replace('[', '')
		.replace(']', '')
		.replace(/\s+/g, ',')
		.replace(/\t/g, ',')
		.replace(/(\r\n|\n)/g, ',')
		.replace(/,{2,}/g, ',');

	if (replaced[0] === ',') {
		replaced = replaced.substring(1, replaced.length);
	}

	if (replaced[replaced.length - 1] === ',') {
		replaced = replaced.substring(0, replaced.length - 1);
	}

	return replaced;
};

export const parseData = (data: string) => {
	data = prepareData(data);
	return data.split(',').map((item) => parseFloat(item));
};

export const validateInput = (data: string) => {
	// if no data, disable submit
	if (data === undefined || data === '') {
		return false;
	}

	data = prepareData(data);

	// if data contains any character other than number, ',' or '.' invalid format
	if (data.match(/[^(,)|(.)|(\-)|(0-9)]/g)) {
		return false;
	}

	const splitData = data.split(',');

	// contains at least 2 pairs
	if (splitData.length < 4) {
		return false;
	}

	// has even number of items
	if (splitData.length % 2 === 1) {
		return false;
	}

	// has no empty items
	let hasEmptyItem = false,
		containsNaN = false;

	splitData.forEach((value) => {
		if (value === '') {
			hasEmptyItem = true;
		} else if (isNaN(parseFloat(value))) {
			containsNaN = true;
		}
	});

	return !(hasEmptyItem || containsNaN);
};

export const selectQhFile = (data: string) => {
	if (!validateInput(data as string)) {
		toast.error(i18n.t('FREE_PROFILE_PARAMS_INVALID_FORMAT'));
		return;
	}

	const yValues = [];
	const zValues = [];
	const values = parseData(data as string);
	for (let i = 0; i < values.length; i++) {
		if (i % 2 === 0) {
			yValues.push(values[i]);
		} else {
			zValues.push(values[i]);
		}
	}

	const selectedData = [...yValues, ...zValues].join(',');
	toast.success(i18n.t('FREE_PROFILE_PARAMS_IMPORTED'));

	return selectedData;
};
