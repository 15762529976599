import React from 'react';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';

import { useTranslation } from 'react-i18next';
import './styles.scss';
import { IStepOne } from './types';

const FormStepOne = ({ formik, countries, formStep }: IStepOne) => {
	const { t } = useTranslation();
	return (
		<div className={formStep !== 1 ? 'hidden' : ' flex flex-1 flex-col overflow-y-auto'}>
			<Fade timeout={1000} in={formStep === 1}>
				<div className="flex flex-col">
					<TextField
						className="mt-2"
						required
						label={t('NAME')}
						name="name"
						value={formik.values.name}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={formik.touched.name && Boolean(formik.errors.name)}
					/>
					<div className="min-h-[20px] w-full text-sm text-red-500">
						{formik.touched.name && formik.errors.name}
					</div>
					<TextField
						className="mt-3 md:mt-5"
						label={t('ADDRESS')}
						name="address"
						value={formik.values.address}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={formik.touched.address && Boolean(formik.errors.address)}
					/>
					<div className="min-h-[20px] w-full text-sm text-red-500">
						{formik.touched.address && formik.errors.address}
					</div>
					<TextField
						className="mt-3 md:mt-5"
						label={t('EMAIL')}
						name="email"
						value={formik.values.email}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={formik.touched.email && Boolean(formik.errors.email)}
					/>
					<div className="min-h-[20px] w-full text-sm text-red-500">
						{formik.touched.email && formik.errors.email}
					</div>
					<TextField
						className="mt-3 md:mt-5"
						label={t('PHONE_NUMBER')}
						name="phone"
						value={formik.values.phone}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						error={formik.touched.phone && Boolean(formik.errors.phone)}
					/>
					<div className="min-h-[20px] w-full text-sm text-red-500">
						{formik.touched.phone && formik.errors.phone}
					</div>
					<FormControl className="mt-3 md:mt-5" required>
						<InputLabel id="demo-simple-select-required-label">{t('SELECT_COUNTRY')}</InputLabel>
						<Select
							value={formik.values.country ?? ''}
							label={t('SELECT_COUNTRY')}
							name="country"
							onChange={formik.handleChange}>
							<MenuItem className="hidden" value={''}></MenuItem>
							{countries?.map((country) => {
								return (
									<MenuItem key={country.name} value={country.code}>
										<div className="mr-2 inline-block">
											<img src={country.flag} alt={'flag'} />
										</div>
										{t(country.name)}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
					<div className="min-h-[20px] w-full text-sm text-red-500">
						{formik.touched.country && formik.errors.country}
					</div>
				</div>
			</Fade>
		</div>
	);
};

export default FormStepOne;
