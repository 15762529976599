import TextField from '@mui/material/TextField';
import CancelButton from 'components/shared/Button/CancelButton';
import PrimaryButton from 'components/shared/Button/PrimaryButton';
import { OrganizationListRetrieve } from 'interfaces/models/OrganizationListRetrieve';
import React, { useState, useEffect, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CheckBox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';
import DomainIcon from '@mui/icons-material/Domain';
import { useTranslation } from 'react-i18next';
import { queryKeys } from 'rq/constants';
import { useAuth, useLogout } from 'rq/hooks/authHook';
import { queryClient } from 'rq/queryClient';
import { OrganizationsService } from 'services/OrganizationsService/OrganizationService';
import CreateOrganizationModal from '../../components/Modal/CreateOrganization/CreateOrganizationModal';
import { OrganizationCreateUpdateDestroy } from 'interfaces/models/OrganizationCreateUpdateDestroy';

const JoinOrganizations = () => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);

	const { data: user } = useAuth();
	const userId = user?.id as number;

	const { mutate: logout } = useLogout();
	const [publicOrganizations, setPublicOrganizations] = useState<OrganizationListRetrieve[]>();
	const [createOrganizationModalOpen, setCreateOrganizationModalOpen] = useState<boolean>(false);
	const [orgSelectionState, setOrgSelectionState] = useState<number[]>([1]);
	const [orgsCreated, setOrgsCreated] = useState<OrganizationListRetrieve[]>();
	const [searchTerm, setSearchTerm] = useState('');

	useEffect(() => {
		OrganizationsService.organizationsList('true').then((response) => {
			response.sort((a, b) => (a.name.toLowerCase() <= b.name.toLowerCase() ? -1 : 1));
			setPublicOrganizations(response);
			setLoading(false);
		});
	}, []);

	const orgSelection = [...orgSelectionState];

	const selectOrganizations = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const el = event.target as HTMLElement;
		const htmlEl = el.closest('.cursor-pointer') as HTMLElement;
		const orgId = Number(htmlEl.getAttribute('data-org-id'));
		if (el.tagName !== 'INPUT') {
			const checkBoxEl = htmlEl.querySelector('input');
			checkBoxEl?.click();
		} else {
			const findIndex = orgSelection.findIndex((el) => el === orgId);
			if (findIndex >= 0) {
				orgSelection.splice(findIndex, 1);
				if (orgSelection.length === 0) {
					const join_btn = document.querySelector("[aria-label='join-btn']") as HTMLButtonElement;
					join_btn.classList.add('Mui-disabled');
				}
			} else {
				orgSelection.push(orgId);
				if (orgSelection.length === 1) {
					const join_btn = document.querySelector("[aria-label='join-btn']") as HTMLButtonElement;
					join_btn.classList.remove('Mui-disabled');
				}
			}
			event.stopPropagation();
		}
	};

	/*	useEffect(() => {
			setOrgSelectionState(orgSelection);
		}, [orgSelection]);*/

	const logoutHandler = () => {
		logout();
	};

	const createOrgHandler = () => {
		setCreateOrganizationModalOpen(true);
		setOrgSelectionState(orgSelection);
	};

	const closeCreateNewOganizationModal = () => {
		setCreateOrganizationModalOpen(false);
		setOrgSelectionState(orgSelection);
	};

	const onOrgCreation = (org: OrganizationCreateUpdateDestroy) => {
		setOrgSelectionState(orgSelection);
		setOrgsCreated((prevState) => [org as OrganizationListRetrieve].concat(prevState ?? []));
		scrollToTheTop();
	};

	const joinOrganizationsHandler = () => {
		if (orgSelection.length === 0) {
			return;
		}
		OrganizationsService.joinOrganization({ userId, orgSelection }).then(() => {
			queryClient.invalidateQueries(queryKeys.me);
		});
	};

	const scrollToTheTop = () => {
		const publicOrgList = document.getElementById('org-list');
		publicOrgList?.scrollTo(0, 0);
	};

	const memorizedPublicOrgList = useMemo(() => {
		const publicOrgs = publicOrganizations?.filter((publicOrg) =>
			publicOrg.name.toLowerCase().includes(searchTerm.toLowerCase())
		);

		return publicOrgs?.map((pubOrg: OrganizationListRetrieve) => {
			return (
				<div
					className="flex cursor-pointer flex-row items-center py-1 pl-2 hover:bg-gray-200"
					data-org-id={pubOrg.id}
					key={pubOrg.id}>
					<CheckBox
						data-check={pubOrg.id}
						className="mr-4 p-0 text-secondary"
						defaultChecked={orgSelectionState.includes(pubOrg.id)}
					/>
					<Avatar className="mx-0 mr-2 h-8 w-8 bg-blue-300" src={pubOrg.logo}>
						<DomainIcon />
					</Avatar>
					<div className="flex flex-col justify-center">
						<div>{pubOrg.name}</div>
					</div>
				</div>
			);
		});
	}, [publicOrganizations, searchTerm]);

	return (
		<Grid
			className="flex h-full w-full flex-col justify-center"
			item
			xs={12}
			sm={8}
			md={5}
			component={Paper}
			elevation={6}
			square>
			<Box
				className="max-h-full overflow-y-auto"
				sx={{
					px: 4,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center'
				}}>
				<img
					src={'https://discharge.ch/images/discharge_logo_mobile.png'}
					className={'m-4 h-16 w-16'}
					alt={'Discharge logo'}
				/>
				<Typography component="h1" variant="h5">
					{t('JOIN_PUBLIC_ORGANIZATION')}
				</Typography>
				<p className="mt:2 text-sm md:mt-4">{t('JOIN_ORG_DESCRIPTION_INITIAL')}</p>
				<div className={'my-3 flex w-full justify-center '}>
					<TextField
						value={searchTerm}
						variant={'standard'}
						label={t('SEARCH_ORGS')}
						onChange={(e) => {
							setSearchTerm(e.target.value);
						}}></TextField>
				</div>
				{/*<div className={'flex flex-col'}>
					<span>{t('SELECTED')}</span>
					{orgSelectionState.map((orgId) => {
						return (
							<span className={'mr-2'} key={`selected-org-${orgId}`}>
								{publicOrganizations?.find((org) => org.id === orgId)?.name}
							</span>
						);
					})}
				</div>*/}
				<div className="mt-1 w-full">
					<div className="flex max-h-[60vh] min-h-[60vh] flex-col items-center justify-center overflow-y-auto">
						{loading ? (
							<div className="flex h-full flex-col items-center justify-center text-secondary">
								<CircularProgress size={75} thickness={5} />
								<div>{t('LOADING_PUBLIC_ORGS')}</div>
							</div>
						) : (
							<div
								id="org-list"
								className="mt-2 max-h-full w-full overflow-y-auto md:mt-4"
								onClick={selectOrganizations}>
								{orgsCreated?.map((orgCreated: OrganizationListRetrieve) => {
									return (
										<div
											className="flex cursor-pointer flex-row items-center py-1 pl-2 hover:bg-gray-200"
											data-org-id={orgCreated.id}
											key={orgCreated.id}>
											<CheckBox
												data-check={orgCreated.id}
												className="mr-4 p-0 text-secondary"
												defaultChecked={true}
												disabled
											/>
											<Avatar className="mx-0 mr-2 h-8 w-8 bg-blue-300" src={orgCreated.logo}>
												<DomainIcon />
											</Avatar>
											<div className="flex flex-col justify-center">
												<div>{orgCreated.name}</div>
											</div>
										</div>
									);
								})}

								{memorizedPublicOrgList}
							</div>
						)}
					</div>
					<div className="mt-4 mb-2 flex flex-col-reverse justify-between text-secondary sm:flex-row md:mt-8">
						<div className="mt-6 flex flex-row justify-center sm:mt-0">
							<CancelButton className="h-9 sm:mr-4" text={t('LOGOUT')} onClick={logoutHandler} />
						</div>
						<div className="flex flex-row justify-center gap-4 ">
							<PrimaryButton
								className="h-9"
								disabled={!publicOrganizations}
								text={t('CREATE')}
								onClick={createOrgHandler}
							/>
							<PrimaryButton
								className=" h-9"
								aria-label="join-btn"
								disabled={!publicOrganizations}
								text={t('JOIN')}
								type="submit"
								onClick={joinOrganizationsHandler}
							/>
						</div>
					</div>
				</div>
			</Box>
			{createOrganizationModalOpen && (
				<CreateOrganizationModal
					open={createOrganizationModalOpen}
					closeModal={closeCreateNewOganizationModal}
					afterCreatingOrganization={onOrgCreation}
				/>
			)}
		</Grid>
	);
};

export default JoinOrganizations;
