import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import GCPCoordinates from 'components/EditGCPs/GCPCoordinates';
import GCPMeasurements from 'components/EditGCPs/GCPMeasurements';
import Modal from 'components/shared/Modal/Modal';
import { TabPanel } from 'components/shared/TabPanel/TabPanel';
import { useGlobalStore } from 'global-state/useStore';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const EditGCP = () => {
	const { t } = useTranslation();

	const { open, stationDetails } = useGlobalStore((state) => state.editGCPModal);
	const toggleEditGCPModal = useGlobalStore((state) => state.toggleEditGCPModal);

	const [pointsDefined] = useState(
		stationDetails &&
			stationDetails.markers_coordinates &&
			Math.floor(stationDetails.markers_coordinates.length / 3)
	);

	const [tabValue, setTabValue] = useState<number>(0);
	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue);
	};
	const handleClose = () => {
		toggleEditGCPModal({ open: false, station_id: null, stationDetails: null });
	};

	return (
		<Modal modalTitle={t('MARKERS')} open={open} closeModal={handleClose}>
			<div className={'flex w-full flex-col gap-4 '}>
				<div>
					<Tabs
						className="w-full max-w-3xl"
						variant="scrollable"
						allowScrollButtonsMobile
						value={tabValue}
						onChange={handleTabChange}>
						{pointsDefined === 4 && <Tab label={t('MEASUREMENTS')} />}
						<Tab label={t('COORDINATES')} />
					</Tabs>
					<div className="w-full max-w-3xl  border border-solid border-[#bfbfbf] bg-white">
						{pointsDefined === 4 && (
							<TabPanel value={tabValue} index={0}>
								<div className="p-4">
									<GCPMeasurements />
								</div>
							</TabPanel>
						)}
						<TabPanel value={tabValue} index={pointsDefined === 4 ? 1 : 0}>
							<div className="flex flex-col p-4">
								<GCPCoordinates />
							</div>
						</TabPanel>
					</div>
				</div>
			</div>
		</Modal>
	);
};
export default EditGCP;
