export type ProcessingStep = {
	stepNumber: number;
	name: string;
	displacementStep: boolean;
	nextDisplacementStep?: number;
};

export const STEPS = {
	UPLOAD: 'UPLOAD',
	CAMERA_CALIBRATION: 'CAMERA_CALIBRATION',
	WATER_LEVEL: 'WATER_LEVEL',
	RESULTS: 'RESULTS'
};
const UPLOAD_STEP = {
	stepNumber: 0,
	name: STEPS.UPLOAD,
	displacementStep: true,
	nextDisplacementStep: 3
};

const CAMERA_CALIBRATION_STEP = {
	stepNumber: 1,
	name: STEPS.CAMERA_CALIBRATION,
	displacementStep: false
};

const WATER_LEVEL_STEP = {
	stepNumber: 2,
	name: STEPS.WATER_LEVEL,
	displacementStep: false
};

const RESULTS_STEP = {
	stepNumber: 3,
	name: STEPS.RESULTS,
	displacementStep: true
};

export const PROCESSING_STEPS: ProcessingStep[] = [
	UPLOAD_STEP,
	CAMERA_CALIBRATION_STEP,
	WATER_LEVEL_STEP,
	RESULTS_STEP
];
