import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import Modal from 'components/shared/Modal/Modal';
import { useGlobalStore } from 'global-state/useStore';
import { DischargeStationCurve } from 'interfaces/models/DischargeStationCurve';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { validateInput } from 'utils/hqRatingCurve';

const EditQHDataModal = () => {
	const { t } = useTranslation();
	const toggleEditQHDataModal = useGlobalStore((state) => state.toggleEditQHDataModal);
	const ratingCurveFormData = useGlobalStore((state) => state.ratingCurveFormData);
	const setRatingCurveFormData = useGlobalStore((state) => state.setRatingCurveFormData);

	const { open } = useGlobalStore((state) => state.editQHDataModal);
	const [hqInputValue, sethQInputValue] = useState(
		ratingCurveFormData.rating_curve?.parameters.join(',')
	);
	const [hqInputValid, setHqInputValid] = useState(validateInput(hqInputValue ?? ''));

	useEffect(() => {
		setHqInputValid(validateInput(hqInputValue ?? ''));
	}, [hqInputValue]);

	const handleUpdate = () => {
		const curve = { ...ratingCurveFormData.rating_curve };
		if (curve && hqInputValue) {
			curve.parameters = hqInputValue?.split(',').map(Number);
			setRatingCurveFormData({ formToggled: true, rating_curve: curve as DischargeStationCurve });
			toggleEditQHDataModal();
			toast.success(t('HQ_PAIRS_UPDATED'));
		}
	};

	return (
		<Modal closeModal={toggleEditQHDataModal} open={open} modalTitle={t('HQ_PAIRS')}>
			<div className={'flex w-full flex-col gap-4 p-4'}>
				<TextField
					variant={'standard'}
					multiline={true}
					onChange={(e) => {
						sethQInputValue(e.target.value);
					}}
					rows={15}
					className={'w-full '}
					value={hqInputValue}
				/>
				<div className={'flex w-auto flex-wrap gap-4 self-center'}>
					<Button
						disabled={!hqInputValid}
						onClick={handleUpdate}
						variant={'contained'}
						className={'normal-case'}>
						{t('UPDATE')}
					</Button>
					<Button
						onClick={toggleEditQHDataModal}
						variant={'contained'}
						className={'bg-accent normal-case'}>
						{t('CANCEL')}
					</Button>
				</div>
			</div>
		</Modal>
	);
};
export default EditQHDataModal;
