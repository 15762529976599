import TextField from '@mui/material/TextField';
import { IFilterComponentProps } from 'components/Measurements/MeasurementsFilter/types';
import { useGlobalStore } from 'global-state/useStore';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';

const StringFilter: FunctionComponent<IFilterComponentProps> = ({
	filterFieldNumber,
	selectedFilter
}) => {
	const { t } = useTranslation();
	const selectedMeasurementFilters = useGlobalStore((state) => state.selectedMeasurementFilters);
	const setSelectedMeasurementFilter = useGlobalStore(
		(state) => state.setSelectedMeasurementFilter
	);

	const [selectedFilterState, setSelectedFilterState] = useState(selectedFilter);

	const handleFilterValueChange = (value: string) => {
		const filter = { ...selectedFilter };
		const filters = [...selectedMeasurementFilters];
		filter.filterValue = value;

		filters[filterFieldNumber] = filter;
		setSelectedMeasurementFilter(filters);
		setSelectedFilterState(filter);
	};

	return (
		<>
			<TextField
				size={'small'}
				label={t('VALUE')}
				onChange={(e) => handleFilterValueChange(e.target.value)}
				value={selectedFilterState.filterValue}></TextField>
		</>
	);
};
export default StringFilter;
