import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import './res/localization/i18n';

import * as Sentry from '@sentry/react';

import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { queryClient } from 'rq/queryClient';
import { BrowserRouter } from 'react-router-dom';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const theme = createTheme({
	palette: {
		primary: {
			main: '#067BC2'
		},
		secondary: {
			main: '#F4F2F3'
		},
		error: { main: '#DC602E' }
	}
});

Sentry.init({
	dsn: 'https://7186b6ee81f54d7f9fde0b20967a5bf9@o155943.ingest.sentry.io/4505001608740864',
	environment: process.env.NODE_ENV,
	integrations: [new Sentry.BrowserTracing()],
	tracesSampleRate: 1.0,
	enabled: process.env.NODE_ENV !== 'development'
});

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<QueryClientProvider client={queryClient}>
				<div id={'leafletmap'} />
				<BrowserRouter>
					<ThemeProvider theme={theme}>
						<App />
					</ThemeProvider>
				</BrowserRouter>
				<ReactQueryDevtools initialIsOpen={false} />
			</QueryClientProvider>
		</LocalizationProvider>
	</React.StrictMode>
);

reportWebVitals();
