import { UserListRetrieve } from 'interfaces/models/UserListRetrieve';
import React, { useState } from 'react';
import Fade from '@mui/material/Fade';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useAuth } from 'rq/hooks/authHook';

import { IStepTwo } from './types';
import { useTranslation } from 'react-i18next';
import { displayUserFullName } from 'helper/helperFunctions';
import { fetchUsersGlobal } from 'helper/requests';
import IconWithTooltip from '../../shared/Tooltip/IconWithTooltip';

const FormStepTwo = ({ formStep, usersList, setUsersList }: IStepTwo) => {
	const { t } = useTranslation();
	const { data: user } = useAuth();

	const [filter, setFilter] = useState<string>('');
	const [filterTimeout, setFilterTimeout] = useState<any>();
	const [filterResult, setFilterResult] =
		useState<{ id: number; first_name: string; last_name: string; username: string }[]>();
	const [showFilteredResult, setShowFilteredResult] = useState<boolean>(false);

	const fetchUsers = (filter: string) => {
		fetchUsersGlobal(filter).then((response) => {
			setFilterResult(response);
		});
	};

	const filterHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		clearTimeout(filterTimeout);
		setFilter(event.target.value);

		if (event.target.value.length > 2) {
			setFilterTimeout(setTimeout(fetchUsers.bind(null, event.target.value), 1000));
			setShowFilteredResult(true);
		} else {
			setShowFilteredResult(false);
		}
	};

	const handleFocus = () => {
		if (filter.length < 3) {
			return;
		}
		if (filterResult) {
			setShowFilteredResult(true);
		}
	};

	const handleBlur = () => {
		setTimeout(() => {
			//handleBlut has the highest priority, therefore withtout timeout, it would close the filtered users and
			//onClick on user would not occur because the element would not be visible anymore
			setShowFilteredResult(false);
		}, 100);
	};

	const addUserToOrganization = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const htmlEl = event.target as HTMLElement;
		const uid = htmlEl.closest('.single-user')?.getAttribute('data-uid');
		const user = filterResult?.find((user) => user.id === Number(uid));

		if (usersList.some((user) => user.id === Number(uid))) return;
		setUsersList((prevState) =>
			prevState.concat([
				{ id: Number(user?.id), name: displayUserFullName(user as UserListRetrieve) ?? '' }
			])
		);
	};

	const removeUserFromOrganization = (uid: number) => {
		setUsersList((prevState) => prevState.filter((user) => user.id !== uid));
	};

	return (
		<div className={formStep !== 2 ? 'hidden' : 'flex-1'}>
			<Fade timeout={2000} in={formStep === 2}>
				<div className="flex h-full flex-col">
					<div className="mb-4 flex flex-col items-center justify-center md:mb-8">
						<div className="flex flex-row">
							<SearchIcon className="mt-4 mr-4" />
							<div className="relative">
								<TextField
									onBlur={handleBlur}
									onFocus={handleFocus}
									id="standard-basic"
									label={t('FIND_USER')}
									value={filter}
									onChange={filterHandler}
									variant={'standard'}
								/>
								{showFilteredResult && (
									<div className="z-5 absolute top-full left-2/4 mt-2 flex w-full w-full -translate-x-1/2 transform flex-col items-center justify-center">
										<div
											className="w-full min-w-max border border-gray-300 bg-primary"
											onClick={addUserToOrganization}>
											{filterResult?.map((result) => {
												return (
													<div
														data-uid={result.id}
														key={result.id}
														className="single-user cursor-pointer justify-between border-b border-gray-300 px-4 py-2 hover:bg-gray-400">
														<div>
															{result.username}{' '}
															{result.last_name || result.first_name
																? ' - ' + result.first_name + ' ' + result.last_name
																: ''}
														</div>
													</div>
												);
											})}
										</div>
									</div>
								)}
							</div>
						</div>
					</div>

					<div className="w-full flex-1">
						{usersList.map((eachUser) => {
							return (
								<div
									key={eachUser.id}
									className="flex flex-row justify-between border border-gray-200 bg-white px-4 py-4">
									<div>{eachUser.name}</div>
									{eachUser.id !== user?.id && (
										<IconWithTooltip
											title={t('REMOVE')}
											icon={
												<RemoveCircleOutlineIcon
													className="text-red-500"
													onClick={removeUserFromOrganization.bind(null, eachUser.id)}
												/>
											}
										/>
									)}
								</div>
							);
						})}
					</div>
				</div>
			</Fade>
		</div>
	);
};

export default FormStepTwo;
