import { apiClient } from 'agent/apiClient';
import { IProfileCalibrationService } from 'services/ProfileCalibration/interface';

const ProfileCalibrationService: IProfileCalibrationService = {
	getCalibrationData: async (data, isWithPlot) => {
		return await apiClient
			.post(`discharge/profile-calibration/?with_plot=${isWithPlot ? 1 : ''}`, data)
			.then((response) => response.data);
	}
};
export default ProfileCalibrationService;
