import { Download } from '@mui/icons-material';
import { Button } from '@mui/material';
import CrossSectionFreeProfileChart from 'components/CrossSection/CrossSectionFreeProfileChart';
import Modal from 'components/shared/Modal/Modal';
import PermissionView from 'components/shared/PermissionsView/PermissionView';
import { useGlobalStore } from 'global-state/useStore';
import { DischargeStationDetails } from 'interfaces/models/DischargeStationDetails';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStationDetails } from 'rq/hooks/stationHook';
import { downloadCrossSection } from 'utils/freeProfileParamsHelper';
import { DischargeKeeperStationDetails } from '../../../../interfaces/models/DischargeKeeperStationDetails';
import useStationDataFromStore from '../../../../hooks/useStationDataFromStore';

const FreeProfileChartModal = () => {
	const { t } = useTranslation();
	const { open, station_id } = useGlobalStore((state) => state.freeProfileChartModal);
	const selectedSiteId = useGlobalStore((state) => state.selectedSiteId);
	const toggleFreeProfileChartModal = useGlobalStore((state) => state.toggleFreeProfileChartModal);

	const { data: stationDetails } = useStationDetails<
		DischargeStationDetails | DischargeKeeperStationDetails
	>(Number(selectedSiteId), Number(station_id), { enabled: !!selectedSiteId && !!station_id });

	const { stationData } = useStationDataFromStore(stationDetails);

	const handleModalClose = () => {
		toggleFreeProfileChartModal({
			open: false,
			station_id: undefined
		});
	};
	return (
		<>
			<Modal modalTitle={t('FREE_PROFILE_CHART_PREVIEW')} open={open} closeModal={handleModalClose}>
				<div className={'flex w-full max-w-[50rem] flex-col justify-center p-4 '}>
					<PermissionView requiredPermissions={['manage_sites']} showFallbackComponent={false}>
						<div className={'self-end'}>
							<Button
								variant={'contained'}
								onClick={() => downloadCrossSection(stationData as DischargeStationDetails)}>
								<span className={'normal-case'}>
									{t('DOWNLOAD')} <Download />
								</span>
							</Button>
						</div>
					</PermissionView>
					<div>{<CrossSectionFreeProfileChart />}</div>
				</div>
			</Modal>
		</>
	);
};
export default FreeProfileChartModal;
