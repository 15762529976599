import { UserCreateUpdate } from 'interfaces/models/UserCreateUpdate';
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from 'react-query';
import { queryKeys } from '../constants';
import UsersService from '../../services/UsersService/UsersService';
import { UserListRetrieve } from 'interfaces/models/UserListRetrieve';
import { MembershipCreateUpdateDestroy } from 'interfaces/models/MembershipCreateUpdateDestroy';
import { MembershipPermissionCreateUpdate } from 'interfaces/models/MembershipPermissionCreateUpdate';

export const useGetUsers = ({
	orgId,
	search,
	page,
	pageSize
}: {
	orgId: number;
	search?: string;
	page?: number;
	pageSize?: number;
}) => {
	return useQuery<{
		count: number;
		next?: string | null;
		previous?: string | null;
		results: Array<UserListRetrieve>;
	}>(
		[queryKeys.users, `search-${search}`, `page-${page}`, `pageSize-${pageSize}`, `orgId-${orgId}`],
		() => UsersService.getAllUsers(search, page, pageSize),
		{ keepPreviousData: true }
	);
};
export const useGetUserDetails = (
	userId: string,
	options?: Omit<
		UseQueryOptions<UserListRetrieve, unknown, UserListRetrieve, any>,
		'queryKey' | 'queryFn'
	>
) => {
	return useQuery<UserListRetrieve>(
		[queryKeys.user, userId],
		() => UsersService.getUserDetails(userId),
		{ ...options }
	);
};

export function useRemoveUserFromOrganization(
	options?: Omit<UseMutationOptions<void, unknown, unknown, unknown>, 'mutationFn'>
) {
	return useMutation(
		({ membershipId, userId }: { membershipId: string; userId: string }) =>
			UsersService.deleteUserMembership(membershipId, userId),
		{
			...options
		}
	);
}

export function useUpdateUserMemberships(
	options?: Omit<
		UseMutationOptions<MembershipCreateUpdateDestroy, unknown, unknown, unknown>,
		'mutationFn'
	>
) {
	return useMutation(
		({
			membershipId,
			userId,
			data
		}: {
			membershipId: string;
			userId: string;
			data: MembershipCreateUpdateDestroy;
		}) => UsersService.updateUserMemberships(membershipId, userId, data),
		{
			...options
		}
	);
}

export function useCreateUserMembership(
	options?: Omit<
		UseMutationOptions<MembershipCreateUpdateDestroy, unknown, unknown, unknown>,
		'mutationFn'
	>
) {
	return useMutation(
		({
			userId,
			data
		}: {
			userId: string;
			data: { organization: string; permissions: MembershipPermissionCreateUpdate };
		}) => UsersService.createUserMembership(userId, data),
		{
			...options
		}
	);
}

export function useUpdateUser(
	options?: Omit<UseMutationOptions<UserCreateUpdate, unknown, unknown, unknown>, 'mutationFn'>
) {
	return useMutation(
		({ userId, data }: { userId: string; data: UserCreateUpdate }) =>
			UsersService.updateUser(userId, data),
		{
			...options
		}
	);
}
