import { apiClient } from 'agent/apiClient';
import { IStationsService } from 'services/StationService/interface';

const StationService: IStationsService = {
	getStations: async (site_id: number) => {
		return await apiClient.get(`/sites/${site_id}/stations/`).then((res) => res.data);
	},
	getStationDetails: async <Type>(site_id: number, station_id: number) => {
		return await apiClient
			.get(`/sites/${site_id}/stations/${station_id}/`)
			.then<Type>((res) => res.data);
	},
	createStation: async <Type>(site_id: number, data: Omit<Type, 'station_id'>) => {
		return await apiClient.post(`/sites/${site_id}/stations/`, data).then((res) => res.data);
	},
	updateStation: async <Type>(
		site_id: number,
		station_id: number,
		data: Omit<Type, 'station_id'>
	) => {
		return await apiClient
			.put(`/sites/${site_id}/stations/${station_id}/`, data)
			.then((res) => res.data);
	},
	deleteStation: async (site_id: number, station_id: number) => {
		return await apiClient
			.delete(`/sites/${site_id}/stations/${station_id}/`)
			.then((res) => res.data);
	},
	getCustomConfig: async (site_id: number, station_id: number) => {
		return await apiClient
			.get(`/sites/${site_id}/stations/${station_id}/custom-config/`)
			.then((res) => res.data);
	},
	uploadCustomConfig: async (
		site_id: number,
		station_id: number,
		config: Record<string, unknown>
	) => {
		return await apiClient
			.post(`/sites/${site_id}/stations/${station_id}/custom-config/`, { config: config })
			.then((res) => res.data);
	},
	getSnapshotConfig: async (site_id: number, station_id: number) => {
		return await apiClient
			.get(`/sites/${site_id}/stations/${station_id}/snapshot-config/history/`)
			.then((res) => res.data);
	},
	uploadSnapshotConfig: async (
		site_id: number,
		station_id: number,
		config: Record<string, unknown>
	) => {
		return await apiClient
			.post(`/sites/${site_id}/stations/${station_id}/snapshot-config/`, { config: config })
			.then((res) => res.data);
	},
	getDischargeCurves: async ({ site_id, station_id }) => {
		return await apiClient
			.get(`/sites/${site_id}/stations/${station_id}/dischargecurves/`)
			.then((res) => res.data);
	},
	createDischargeCurve: async ({ site_id, station_id, curve }) => {
		return await apiClient
			.post(`/sites/${site_id}/stations/${station_id}/dischargecurves/`, curve)
			.then((res) => res.data);
	},
	updateDischargeCurve: async ({ site_id, station_id, curve }) => {
		return await apiClient
			.put(`/sites/${site_id}/stations/${station_id}/dischargecurves/${curve.id}/`, curve)
			.then((res) => res.data);
	},
	deleteDischargeCurve: async ({ site_id, station_id, curve_id }) => {
		return await apiClient
			.delete(`/sites/${site_id}/stations/${station_id}/dischargecurves/${curve_id}/`)
			.then((res) => res.data);
	},
	deployDischargeKeeperConfig: async ({ site_id, station_id }) => {
		return await apiClient
			.put(`/sites/${site_id}/stations/${station_id}/deploy/`)
			.then((res) => res.data);
	},
	getAdvancedConfig: async (site_id: number, station_id: number) => {
		return await apiClient
			.get(`/sites/${site_id}/stations/${station_id}/advanced-config/`)
			.then((res) => res.data);
	},
	uploadAdvancedConfig: async (
		site_id: number,
		station_id: number,
		config: Record<string, unknown>
	) => {
		return await apiClient
			.put(`/sites/${site_id}/stations/${station_id}/advanced-config/`, {
				advanced_configuration: config
			})
			.then((res) => res.data);
	}
};
export default StationService;
