import React from 'react';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';
import { useGlobalStore } from '../../../global-state/useStore';
import PrimaryButton from '../Button/PrimaryButton';

const SaveOrDiscardChangesDialog = () => {
	const { t } = useTranslation();

	const saveOrDiscardChangesDialog = useGlobalStore((state) => state.unsavedChangesWarningDialog);
	const toggleSaveOrDiscardDialog = useGlobalStore(
		(state) => state.toggleUnsavedChangesWarningDialog
	);

	/*
		const confirmClick = () => {
			saveOrDiscardChangesDialog?.confirmAction();
			closeModal();
		};
		const discardClick = () => {
			saveOrDiscardChangesDialog?.discardAction();
			closeModal();
		};
	*/

	const closeModal = () => {
		toggleSaveOrDiscardDialog({
			dialogOpen: false,
			message: '',
			headerTitle: ''
			/*	confirmAction: () => null,
				discardAction: () => null,
				cancelAction: () => null,
				confirmActionLabel: '',
				discardActionLabel: '',
				cancelActionLabel: ''*/
		});
	};
	return (
		<>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={saveOrDiscardChangesDialog?.dialogOpen ?? false}
				onClose={closeModal}
				closeAfterTransition
				className="shadow-xl">
				<Fade in={saveOrDiscardChangesDialog?.dialogOpen}>
					<Box className="absolute left-1/2 top-1/2 w-max max-w-[90%] -translate-x-1/2 -translate-y-1/2 transform overflow-y-auto bg-primary shadow-xl shadow-gray-900 outline-none">
						{saveOrDiscardChangesDialog?.headerTitle && (
							<div className="flex w-full flex-row justify-center bg-secondary py-4 text-xl font-medium text-white">
								{saveOrDiscardChangesDialog?.headerTitle}
							</div>
						)}
						<div className="m-4 flex flex-col md:m-6">
							<div className="w-[25rem] max-w-[90%] justify-center self-center text-center">
								{saveOrDiscardChangesDialog?.message}
							</div>
							<div className="my-4 mt-8 flex h-9 flex-row justify-center md:justify-end">
								<PrimaryButton
									className="mr-4 h-9"
									onClick={closeModal}
									text={t(/*saveOrDiscardChangesDialog?.cancelActionLabel?.toUpperCase() ??*/ 'OK')}
								/>
								{/*					<PrimaryButton
									className={'ml-4 h-9 text-danger'}
									onClick={discardClick}
									text={t(
										saveOrDiscardChangesDialog?.confirmActionLabel?.toUpperCase() ?? 'DISCARD'
									)}
								/>
								<PrimaryButton
									className={'ml-4 h-9'}
									onClick={confirmClick}
									text={t(saveOrDiscardChangesDialog?.confirmActionLabel?.toUpperCase() ?? 'YES')}
								/>*/}
							</div>
						</div>
					</Box>
				</Fade>
			</Modal>
		</>
	);
};
export default SaveOrDiscardChangesDialog;
