import { useGlobalStore } from 'global-state/useStore';
import React from 'react';
import List from '@mui/material/List';
import { MainListItems } from './MenuItems';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Chat } from '@mui/icons-material';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';

const drawerWidth = 200;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
	({ theme, open }) => ({
		'& .MuiDrawer-paper': {
			[theme.breakpoints.down(768)]: {
				width: open ? '100vw' : 0
			},
			position: 'relative',
			zIndex: 10,
			whiteSpace: 'nowrap',
			color: 'white',
			width: drawerWidth,
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen
			}),
			boxSizing: 'border-box',
			...(!open && {
				overflowX: 'hidden',
				transition: theme.transitions.create('width', {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen
				}),
				width: '3.5rem'
			})
		}
	})
);

const NavBar = ({ open }: { open: boolean }) => {
	const { t } = useTranslation();
	const { isSeba } = useGlobalStore((state) => state.hostname);
	const feedbackLinkOptions = {
		emailTo: 'discharge@photrack.ch',
		emailCc: 'info@encode.hr',
		emailSub: 'Discharge Feedback'
	};
	return (
		<>
			<Drawer variant="permanent" className={'h-full max-h-[100vh] '} open={open}>
				<List
					component="nav"
					className={'flex h-full max-h-[100vh] flex-col justify-between bg-secondary'}>
					<MainListItems />
					<div>
						<div
							onClick={(e) => {
								e.preventDefault();
								window.location.href =
									'mailto:' +
									feedbackLinkOptions.emailTo +
									'?cc=' +
									feedbackLinkOptions.emailCc +
									'&subject=' +
									feedbackLinkOptions.emailSub;
							}}>
							<ListItemButton className={'bg-secondary '}>
								<ListItemIcon style={{ minWidth: '2.5rem' }}>
									<Chat className={'text-primary'} />
								</ListItemIcon>
								<ListItemText primary={t('FEEDBACK')} />
							</ListItemButton>
						</div>
						{isSeba && open && <img src={'/assets/seba-vector-logo.svg'} alt={'Seba logo'}></img>}
						{isSeba && !open && (
							<img
								src={'/assets/seba-vector-icon.svg'}
								className={'ml-1 max-h-40'}
								alt={'Seba logo'}></img>
						)}
						{!isSeba && open && (
							<img src={'/assets/Discharge_logotip_menu.svg'} alt={'Discharge logo'}></img>
						)}
						{!isSeba && !open && (
							<img
								src={'/assets/Discharge_icon.png'}
								alt={'Discharge logo'}
								className={'h-14 w-14'}></img>
						)}
					</div>
				</List>
			</Drawer>
		</>
	);
};

export default NavBar;
