import { MyLocation, PushPin } from '@mui/icons-material';
import LocationIcon from '@mui/icons-material/LocationOn';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import Modal from 'components/shared/Modal/Modal';
import { useGlobalStore } from 'global-state/useStore';
import { SiteBasicModel } from 'interfaces/models/SiteBasicModel';
import { Icon, LatLngTuple, PointExpression } from 'leaflet';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { MapContainer, Marker, TileLayer, useMapEvents } from 'react-leaflet';
import { queryKeys } from 'rq/constants';
import { useSite, useUpdateSite } from 'rq/hooks/sitesHook';
import { queryClient } from 'rq/queryClient';

const ChangeSiteLocationModal = () => {
	const changeSiteLocationModalOpen = useGlobalStore((state) => state.changeSiteLocationModalOpen);
	const toggleChangeSiteLocationModal = useGlobalStore(
		(state) => state.toggleChangeSiteLocationModal
	);
	const selectedSiteId = useGlobalStore((state) => state.selectedSiteId);

	const { data: siteDetails } = useSite({ site_id: Number(selectedSiteId) });

	const { mutate: updateSite } = useUpdateSite<SiteBasicModel>();

	const { t } = useTranslation();

	const mapRef = useRef<L.Map>(null);
	const [position, setPosition] = useState<LatLngTuple>([
		siteDetails?.latitude,
		siteDetails?.longitude
	] as LatLngTuple);

	const LocationFinder = () => {
		useMapEvents({
			click(e) {
				setPosition([e.latlng.lat, e.latlng.lng]);
			}
		});
		return null;
	};

	const markerIcon = new Icon({
		iconUrl: '/assets/Map_marker.svg',
		iconSize: [40, 40] as PointExpression
	});

	useEffect(() => {
		mapRef.current?.flyTo(position, mapRef.current?.getZoom(), {
			duration: 0.1
		});
	}, [position]);

	return (
		<>
			<Modal
				open={changeSiteLocationModalOpen}
				modalTitle={'Change site location'}
				closeModal={toggleChangeSiteLocationModal}>
				<div className={'flex w-full flex-col gap-4 md:w-[50rem] xl:flex-row'}>
					<div className={'z-50 h-80 w-full rounded-t-md '}>
						<MapContainer
							center={[siteDetails?.latitude, siteDetails?.longitude] as LatLngTuple}
							className={'rounded-t-md'}
							zoom={18}
							scrollWheelZoom={false}
							bounceAtZoomLimits={true}
							zoomControl={true}
							minZoom={5}
							ref={mapRef}>
							<TileLayer
								attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
								url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
								maxZoom={25}
								className={'rounded-t-md'}
								maxNativeZoom={19}
							/>
							<>
								<LocationFinder></LocationFinder>
								<Marker position={position} icon={markerIcon}></Marker>
							</>
						</MapContainer>
					</div>
					<div className={'my-4 mr-2 flex flex-col items-center gap-4'}>
						<div className={'flex items-center gap-2 align-middle'}>
							<LocationIcon></LocationIcon>
							<TextField
								label={t('LATITUDE')}
								size={'small'}
								type={'number'}
								onChange={(e) => {
									setPosition([Number(e.target.value), position[1]]);
								}}
								className={'w-32 md:w-40'}
								value={position[0]}></TextField>
							<TextField
								label={t('LONGITUDE')}
								size={'small'}
								type={'number'}
								className={'w-32 md:w-40'}
								onChange={(e) => {
									setPosition([position[0], Number(e.target.value)]);
								}}
								value={position[1]}></TextField>
						</div>
						<div className={'flex  gap-2 xl:flex-col'}>
							<Button
								variant={'contained'}
								className={'max-w-xs'}
								onClick={() => {
									mapRef.current?.locate().on('locationfound', function (e) {
										setPosition([e.latlng.lat, e.latlng.lng]);
									});
								}}>
								<div className={'flex gap-2 normal-case'}>
									<MyLocation />
									{t('USE_MY_LOCATION')}
								</div>
							</Button>
							<Button variant={'contained'} className={'max-w-xs'}>
								<div className={'flex gap-2 normal-case'}>
									<PushPin /> {t('SELECT_LOCATION_ON_MAP')}
								</div>
							</Button>
						</div>
						<Button
							variant={'contained'}
							className={'mt-10 max-w-xs bg-accent'}
							onClick={() => {
								const dataForSubmit = {
									site_id: Number(siteDetails?.site_id),
									name: `${siteDetails?.name}`,
									latitude: position[0],
									longitude: position[1]
								};
								if (dataForSubmit) {
									updateSite(dataForSubmit, {
										onSuccess: () => {
											toast.success(t('SITE_LOCATION_CHANGED'));
											toggleChangeSiteLocationModal();
											queryClient.invalidateQueries([
												queryKeys.sites,
												queryKeys.site,
												siteDetails?.site_id
											]);
										}
									});
								}
							}}>
							<div className={'flex gap-2 normal-case'}>{t('UPDATE')}</div>
						</Button>
					</div>
				</div>
			</Modal>
		</>
	);
};
export default ChangeSiteLocationModal;
