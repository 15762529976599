import { checkPermissions } from 'helper/helperFunctions';
import { MembershipPermissionCreateUpdate } from 'interfaces/models/MembershipPermissionCreateUpdate';
import { useMemo } from 'react';

export const useManageStationPermissions = (
	permissions: MembershipPermissionCreateUpdate | null
) => {
	return useMemo(() => {
		return checkPermissions(['manage_sites']);
	}, [permissions]);
};
