import React, { FunctionComponent } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { IconWithTooltipProps } from './types';

const IconWithTooltip: FunctionComponent<IconWithTooltipProps> = ({ title, icon }) => {
	const { t } = useTranslation();
	return (
		<Tooltip
			title={<span className={'text-base'}>{t(title)}</span>}
			className={' cursor-pointer hover:opacity-60'}
			key={title}>
			<span>{icon}</span>
		</Tooltip>
	);
};
export default IconWithTooltip;
