import Button from '@mui/material/Button';
import { IButtonProps } from 'components/shared/Button/types';
import React, { FunctionComponent, PropsWithChildren } from 'react';

const PrimaryButton: FunctionComponent<PropsWithChildren<IButtonProps>> = ({
	text,
	onClick,
	disabled,
	className,
	type,
	name,
	children
}) => {
	return (
		<>
			<Button
				className={`cursor-pointer bg-secondary text-primary hover:opacity-90 disabled:bg-gray-300 ${
					className ?? ''
				}`}
				variant="contained"
				type={type}
				name={name}
				disabled={disabled}
				onClick={onClick}>
				{text}
				{children}
			</Button>
		</>
	);
};
export default PrimaryButton;
