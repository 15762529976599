import SearchIcon from '@mui/icons-material/Search';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { Autocomplete, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import classNames from 'classnames';
import IconWithTooltip from 'components/shared/Tooltip/IconWithTooltip';
import { useGlobalStore } from 'global-state/useStore';
import { SiteDetailsModel } from 'interfaces/models/SiteDetailsModel';
import React, { FunctionComponent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGlobalSearch } from 'rq/hooks/searchHook';
import { useSitesPaginated } from 'rq/hooks/sitesHook';
import useDebounce from 'rq/hooks/useDebounce';
import { SiteModel } from 'rq/interfaces/Sites';

const GlobalSearch: FunctionComponent = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const orgId = useGlobalStore((state) => state.currentOrganization.id);

	const [filter, setFilter] = useState('');

	const autoCompleteRef = useRef<HTMLInputElement>();

	const debouncedSearchQuery = useDebounce(filter);

	const globalSearchToggled = useGlobalStore((state) => state.globalSearchToggled);
	const toggleGlobalSearchBar = useGlobalStore((state) => state.toggleGlobalSearchBar);
	const toggleOrganizationSwitchModal = useGlobalStore(
		(state) => state.toggleOrganizationSwitchModal
	);
	const setSelectedSiteId = useGlobalStore((state) => state.setSelectedSiteId);

	const { data: sites, isFetching: sitesFetching } = useSitesPaginated(
		{
			queryParams: { search: debouncedSearchQuery, page: 1, page_size: 10 }
		},
		{ keepPreviousData: true, enabled: globalSearchToggled.searchType === 'in_organization' }
	);

	const { data: globalSites, isFetching: globalSitesFetching } = useGlobalSearch(
		{
			searchTerm: debouncedSearchQuery,
			entity: 'site'
		},
		{ enabled: filter.length > 2 && globalSearchToggled.searchType === 'everywhere' }
	);

	const theme = createTheme({
		components: {
			MuiAutocomplete: {
				styleOverrides: {
					noOptions: () => ({
						':hover': {
							backgroundColor: 'rgb(229 231 235)',
							cursor: 'pointer'
						}
					}),
					popper: {
						width: 'fit-content'
					}
				}
			}
		}
	});

	const toggleSearchEverywhere = () => {
		globalSearchToggled.searchType !== 'everywhere' && autoCompleteRef.current?.focus();
		toggleGlobalSearchBar({
			toggled:
				globalSearchToggled.searchType !== 'everywhere' ? true : !globalSearchToggled.toggled,
			searchType: globalSearchToggled.searchType === 'everywhere' ? null : 'everywhere'
		});
		setFilter(debouncedSearchQuery);
	};

	const handleSiteFromOtherOrgClick = (organizations: any) => {
		toggleGlobalSearchBar({ toggled: false, searchType: null });
		toggleOrganizationSwitchModal({ organizations: organizations, toggled: true });
	};

	const searchEverywhereOption = () => {
		return sitesFetching || globalSitesFetching ? (
			<div className={'flex w-full justify-center'}>
				<CircularProgress />
			</div>
		) : (
			<div
				className={'flex items-center font-bold text-black'}
				onClick={() => {
					toggleGlobalSearchBar({
						toggled: true,
						searchType: 'everywhere'
					});
				}}>
				<TravelExploreIcon className={'mr-2 text-accent'} />
				<>{t('SEARCH_EVERYWHERE')}</>
			</div>
		);
	};

	return (
		<div className={'flex flex-row items-center'}>
			<ThemeProvider theme={theme}>
				<Autocomplete
					onChange={(e, value) => {
						if (globalSearchToggled.searchType === 'in_organization' && !!value) {
							setSelectedSiteId(value.site_id);
							navigate(`/${orgId}/sites/${value.site_id}`);
							toggleGlobalSearchBar({
								toggled: false,
								searchType: null
							});
						}
					}}
					options={
						globalSearchToggled.searchType === 'in_organization'
							? sites?.results ?? []
							: (globalSites?.site.results as unknown as SiteModel[]) ?? []
					}
					getOptionLabel={(option: SiteDetailsModel) => {
						return option.name;
					}}
					noOptionsText={searchEverywhereOption()}
					size={'small'}
					className={'mr-2 rounded-md bg-primary hover:opacity-90 lg:w-52 xl:w-72'}
					openOnFocus
					clearOnBlur={false}
					sx={{
						'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
							border: '0px'
						}
					}}
					renderOption={(props, option: any) => {
						return (
							<li className={'cursor-pointer p-2'} {...props}>
								<div
									onClick={() => {
										globalSearchToggled.searchType === 'everywhere' &&
											handleSiteFromOtherOrgClick(option.organizations);
									}}>
									{option.name}
									{globalSearchToggled.searchType === 'everywhere' && (
										<div className={' flex flex-wrap text-xs font-bold '}>
											<>{t('ORGANIZATIONS')}:</>
											{option.organizations?.map((e: any, index: number) => {
												return (
													<span key={e.name} className={'ml-1 font-normal '}>
														{e.name}
														{index !== option.organizations.length - 1 && ','}
													</span>
												);
											})}
										</div>
									)}
								</div>
							</li>
						);
					}}
					renderInput={(params) => (
						<TextField
							{...params}
							placeholder={
								globalSearchToggled.searchType === 'everywhere'
									? t('SEARCH_EVERYWHERE')
									: t('SEARCH_SITES')
							}
							onFocus={(e) => {
								e.preventDefault();
								toggleGlobalSearchBar({
									toggled: globalSearchToggled.toggled,
									searchType: globalSearchToggled.searchType
								});
							}}
							inputRef={autoCompleteRef}
							className={classNames(
								{
									'mr-2 border-primary transition-all duration-500 lg:w-52 xl:w-72': true
								},
								{
									'block ': globalSearchToggled.toggled
								},
								{
									hidden: !globalSearchToggled.toggled
								}
							)}
							value={filter}
							onChange={(e) => {
								setFilter(e.target.value);
							}}
						/>
					)}
				/>
			</ThemeProvider>

			<IconWithTooltip
				title={t('SEARCH')}
				icon={
					<SearchIcon
						onClick={() => {
							globalSearchToggled.searchType !== 'in_organization' &&
								autoCompleteRef.current?.focus();
							toggleGlobalSearchBar({
								toggled:
									globalSearchToggled.searchType !== 'in_organization'
										? true
										: !globalSearchToggled.toggled,
								searchType:
									!globalSearchToggled.searchType || globalSearchToggled.searchType === 'everywhere'
										? 'in_organization'
										: null
							});
						}}
						className={'mr-1 text-primary'}
					/>
				}
			/>
			<IconWithTooltip
				title={t('SEARCH_EVERYWHERE')}
				icon={
					<TravelExploreIcon
						onClick={() => {
							toggleSearchEverywhere();
						}}
						className={
							globalSearchToggled.searchType === 'everywhere' ? 'text-accent' : 'text-primary'
						}
					/>
				}
			/>
		</div>
	);
};
export default GlobalSearch;
