import React, { FunctionComponent, useCallback } from 'react';
import { IPermissionView } from './types';
import { checkPermissions } from 'helper/helperFunctions';
import AccessDenied from '../AccessDenied/AccessDenied';

const PermissionView: FunctionComponent<IPermissionView> = ({
	requiredPermissions,
	showFallbackComponent,
	children
}) => {
	const checkAccess = useCallback(() => {
		return checkPermissions(requiredPermissions);
	}, [requiredPermissions]);

	return checkAccess() ? children : showFallbackComponent ? <AccessDenied /> : null;
};
export default PermissionView;
