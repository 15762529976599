import DomainIcon from '@mui/icons-material/Domain';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import PrimaryButton from 'components/shared/Button/PrimaryButton';
import Modal from 'components/shared/Modal/Modal';
import PermissionView from 'components/shared/PermissionsView/PermissionView';
import { useGlobalStore } from 'global-state/useStore';
import { OrganizationListRetrieve } from 'interfaces/models/OrganizationListRetrieve';
import React, { PropsWithChildren, useMemo, useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { queryKeys } from 'rq/constants';
import { useAuth } from 'rq/hooks/authHook';
import { usePublicOrganizations } from 'rq/hooks/organizationsHook';
import { queryClient } from 'rq/queryClient';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { IModalProps } from './types';
import CreateOrganizationModal from '../CreateOrganization/CreateOrganizationModal';
import { OrganizationCreateUpdateDestroy } from 'interfaces/models/OrganizationCreateUpdateDestroy';
import { Lock, LockOpen } from '@mui/icons-material';
import IconWithTooltip from '../../shared/Tooltip/IconWithTooltip';

const OrganizationSwitcher = (props: PropsWithChildren<IModalProps>) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { data: user } = useAuth();
	const { data: otherOrganizations, isLoading: otherOrgLoading } = usePublicOrganizations();
	const userOrganizations = user?.organizations ?? [];
	const organizationSwitcher = useGlobalStore((state) => state.organizationSwitcher);

	const [createOrganizationModalOpen, setCreateOrganizationModalOpen] = useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState('');

	const chooseAnOrganization = (org: OrganizationListRetrieve) => {
		props.handleModalClose();
		navigate(`/${org.id.toString()}`, { state: { orgName: org.name } });
	};

	const createNewOrganization = () => {
		setCreateOrganizationModalOpen(true);
	};

	const closeCreateNewOrganizationModal = () => {
		setCreateOrganizationModalOpen(false);
	};

	const orgCreated = (org: OrganizationCreateUpdateDestroy) => {
		queryClient.invalidateQueries(queryKeys.me);
		navigate(`/${org.id}`);
		props.handleModalClose();
	};
	const userOrganizationsState = useMemo(() => {
		const organizations = userOrganizations.filter((org) =>
			org.name.toLowerCase().includes(searchTerm.toLowerCase())
		);
		return organizations?.map((org: OrganizationListRetrieve) => {
			return (
				<div
					className="flex cursor-pointer flex-row items-center justify-between p-2 hover:bg-gray-200"
					data-org-id={org.id}
					onClick={chooseAnOrganization.bind(this, org)}
					key={org.id}>
					<div className={'flex gap-2'}>
						<Avatar className="mx-0 mr-2 h-10 w-10 bg-blue-300" src={org.logo}>
							<DomainIcon />
						</Avatar>
						<div className="flex flex-col justify-center">
							<div>{org.name}</div>
						</div>
					</div>
					<div>
						<IconWithTooltip
							title={org.is_public ? t('PUBLIC_ORGANIZATION') : t('PRIVATE_ORGANIZATION')}
							icon={org.is_public ? <LockOpen /> : <Lock />}
						/>
					</div>
				</div>
			);
		});
	}, [userOrganizations, searchTerm]);

	const otherOrganizationsState = useMemo(() => {
		const otherOrgs = otherOrganizations?.filter(
			(publicOrg) =>
				!userOrganizations.some((userOrg) => publicOrg.id === userOrg.id) &&
				publicOrg.name.toLowerCase().includes(searchTerm.toLowerCase())
		);

		return otherOrgs?.map((pubOrg: OrganizationListRetrieve) => {
			return (
				<div
					className="flex cursor-pointer flex-row items-center justify-between  p-2 hover:bg-gray-200"
					data-org-id={pubOrg.id}
					onClick={chooseAnOrganization.bind(this, pubOrg)}
					key={pubOrg.id}>
					<div className={'flex gap-2'}>
						<Avatar className="mx-0 mr-2 h-10 w-10 bg-blue-300" src={pubOrg.logo}>
							<DomainIcon />
						</Avatar>
						<div className="flex flex-col justify-center">
							<div>{pubOrg.name}</div>
						</div>
					</div>
					<div>
						<IconWithTooltip
							title={pubOrg.is_public ? t('PUBLIC_ORGANIZATION') : t('PRIVATE_ORGANIZATION')}
							icon={pubOrg.is_public ? <LockOpen /> : <Lock />}
						/>
					</div>
				</div>
			);
		});
	}, [otherOrganizations, searchTerm]);

	return (
		<Modal
			open={props.open}
			closeModal={props.handleModalClose}
			modalTitle={t('SWITCH_ORGANIZATION')}>
			<div className={'flex w-full flex-1 flex-wrap-reverse justify-between gap-2 p-4'}>
				<div className="flex justify-center">
					<TextField
						value={searchTerm}
						variant={'outlined'}
						size={'small'}
						label={t('SEARCH_ORGS')}
						onChange={(e) => {
							setSearchTerm(e.target.value);
						}}
					/>
				</div>
				<div className={'ml-auto'}>
					<PermissionView requiredPermissions={'manage_organization'} showFallbackComponent={false}>
						<PrimaryButton
							onClick={createNewOrganization}
							className={'flex gap-2 self-end bg-secondary normal-case'}>
							<AddCircleOutlineIcon className={'text-white'} />
							<span className={'text-white'}>{t('CREATE_ORGANIZATION')}</span>
						</PrimaryButton>
					</PermissionView>
				</div>
			</div>

			<div className="max-h-[70vh] min-w-[50rem] flex-1 overflow-y-auto p-4">
				{organizationSwitcher.organizations && (
					<div className={'flex justify-center'}>{t('SWITCH_ORG_TO_CONTINUE')}</div>
				)}
				<div className={'flex flex-col gap-4'}>
					<div className={'self-center font-bold'}>{t('USER_ORGANIZATIONS')}</div>
					<div className={'flex flex-col'}>
						{userOrganizationsState.length > 0 ? (
							userOrganizationsState
						) : (
							<div className={'self-center'}>{t('NO_RESULTS')}</div>
						)}
					</div>
					<div className={'self-center font-bold'}>{t('OTHER_ORGANIZATIONS')}</div>
					{otherOrgLoading ? (
						<div className={'flex w-full flex-col items-center justify-center p-10'}>
							<CircularProgress />
							<span>{t('LOADING')}</span>
						</div>
					) : (
						<div className={'flex flex-col '}>
							{otherOrganizationsState && otherOrganizationsState.length > 0 ? (
								otherOrganizationsState
							) : (
								<div className={'self-center'}>{t('NO_RESULTS')}</div>
							)}
						</div>
					)}
				</div>
			</div>
			{createOrganizationModalOpen && (
				<CreateOrganizationModal
					open={createOrganizationModalOpen}
					closeModal={closeCreateNewOrganizationModal}
					afterCreatingOrganization={orgCreated}
				/>
			)}
		</Modal>
	);
};

export default OrganizationSwitcher;
