import { OrganizationCreateUpdateDestroy } from 'interfaces/models/OrganizationCreateUpdateDestroy';
import { OrganizationListRetrieve } from 'interfaces/models/OrganizationListRetrieve';
import { OrganizationPlanList } from 'interfaces/models/OrganizationPlanList';
import { useMutation, UseMutationOptions, useQuery } from 'react-query';
import { queryKeys } from 'rq/constants';
import { OrganizationsService } from 'services/OrganizationsService/OrganizationService';

export const useOrganizations = () => {
	return useQuery<OrganizationListRetrieve[]>(queryKeys.organizations, () =>
		OrganizationsService.organizationsList()
	);
};
export const usePublicOrganizations = () => {
	return useQuery<OrganizationListRetrieve[]>(queryKeys.public_organizations, () =>
		OrganizationsService.organizationsList('true', 'name')
	);
};
export const useOrganizationDetails = (orgId: string) => {
	return useQuery<OrganizationListRetrieve>([queryKeys.organization, orgId], () =>
		OrganizationsService.getOrganizationDetails(orgId)
	);
};

export const useOrganizationPlan = (orgId: string) => {
	return useQuery<OrganizationPlanList>([queryKeys.organization_plan, orgId], () =>
		OrganizationsService.organizationsPlanList(orgId)
	);
};

export function useUpdateOrganization(
	options?: Omit<
		UseMutationOptions<OrganizationCreateUpdateDestroy, unknown, unknown, unknown>,
		'mutationFn'
	>
) {
	return useMutation(
		(data: { organizationId: string; data: OrganizationCreateUpdateDestroy }) =>
			OrganizationsService.updateOrganization(data),
		{
			...options
		}
	);
}
