import { apiClient } from 'agent/apiClient';
import { SiteDetailsModel } from 'interfaces/models/SiteDetailsModel';
import { CountryResponse } from './types';

export const fetchUsersGlobal = (search: string) => {
	return apiClient
		.get(`/users/?search=${search}`, {
			headers: {
				accept: 'application/json'
			},
			data: 'globalUsersGet'
		})
		.then((res) => {
			return res.data;
		});
};

export const getLastMeasurementsCustomFnc = async (last_measurements: boolean, sub: boolean) => {
	return apiClient
		.get(`/sites/?last-measurements=${last_measurements}&sub=${sub}`, {
			headers: {
				accept: 'application/json'
			}
		})
		.then((response) => {
			// Promise.resolve();
			return response.data as SiteDetailsModel[];
		});
};

export const getCountries = async () => {
	return apiClient
		.get(`/countries/`, {
			headers: {
				accept: 'application/json'
			}
		})
		.then((response) => {
			// Promise.resolve();
			return response.data as CountryResponse[];
		});
};
