import { useManageStationPermissions } from 'hooks/useManageStationPermissions';
import { DischargeKeeperStationDetails } from 'interfaces/models/DischargeKeeperStationDetails';
import { DischargeStationDetails } from 'interfaces/models/DischargeStationDetails';
import { MembershipPermissionCreateUpdate } from 'interfaces/models/MembershipPermissionCreateUpdate';
import { useState, useEffect } from 'react';

function useStationInfo(
	currentOrganization: {
		id: number;
		permissions: MembershipPermissionCreateUpdate | null | undefined;
		name: string | null;
	},
	stationDetails: DischargeStationDetails | DischargeKeeperStationDetails
) {
	const manageStationPermission = useManageStationPermissions(
		currentOrganization.permissions as MembershipPermissionCreateUpdate
	);

	const calculateGCPPointsDefined = () => {
		return (
			stationDetails &&
			stationDetails.markers_coordinates &&
			Math.floor(stationDetails.markers_coordinates.length / 3)
		);
	};

	const calculateCrossSectionPointsDefined = () => {
		return (
			stationDetails &&
			stationDetails.profile.free_params &&
			Math.floor(stationDetails.profile.free_params.length / 2)
		);
	};

	const [GCPPointsDefined, setGCPPointsDefined] = useState(calculateGCPPointsDefined());
	const [numberOfCrossSectionPointsDefined, setCrossSectionPointsDefined] = useState(
		calculateCrossSectionPointsDefined()
	);

	useEffect(() => {
		setGCPPointsDefined(calculateGCPPointsDefined());
		setCrossSectionPointsDefined(calculateCrossSectionPointsDefined());
	}, [stationDetails]);

	return {
		manageStationPermission,
		GCPPointsDefined,
		setGCPPointsDefined,
		numberOfCrossSectionPointsDefined,
		setCrossSectionPointsDefined
	};
}

export default useStationInfo;
