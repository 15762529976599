import { Upload } from '@mui/icons-material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import EditIcon from '@mui/icons-material/Edit';
import { Button, FormControlLabel, IconButton, Radio, RadioGroup, TextField } from '@mui/material';
import IconWithTooltip from 'components/shared/Tooltip/IconWithTooltip';
import { useFormik } from 'formik';
import { useGlobalStore } from 'global-state/useStore';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import ProfileCalibrationService from 'services/ProfileCalibration/ProfileCalibrationService';
import { validateFileInput } from 'utils/freeProfileParamsHelper';
import { handleFileRead } from 'utils/functions';
import { scientificNotationNumberPattern } from 'utils/regex';
import * as Yup from 'yup';

const CrossSectionInput = () => {
	const { t } = useTranslation();

	const setCalibrationImg = useGlobalStore((state) => state.setCalibrationImg);

	const uploadInputRef = useRef<HTMLInputElement | null>(null);
	const [file, setFile] = useState({ name: '', content: '', inputAlias: '' });

	const [activeEditFieldIndex, setActiveEditFieldIndex] = useState<number | null>(null);

	const formik = useFormik({
		validationSchema: Yup.object({
			markers: Yup.string()
				.required(t('REQUIRED'))
				.test('markers', t('REQUIRED'), function (value) {
					const { path, createError } = this;

					const validationResult = validateFileInput({
						inputAlias: 'markers',
						profileInputFileType: profileInputFileTypes[1],
						isProfile3D: formik.values.profileDimensions === '3D',
						fileContent: value ?? file.content
					});

					return (
						validationResult.inputValid || createError({ path, message: validationResult.errMsg })
					);
				}),
			markers_file_name: Yup.string().required(t('REQUIRED')),
			profile: Yup.string()
				.required(t('REQUIRED'))
				.test('profile', t('REQUIRED'), function (value) {
					const { path, createError } = this;

					const validationResult = validateFileInput({
						inputAlias: 'profile',
						profileInputFileType: profileInputFileTypes[0],
						isProfile3D: formik.values.profileDimensions === '3D',
						fileContent: value ?? file.content
					});

					return (
						validationResult.inputValid || createError({ path, message: validationResult.errMsg })
					);
				}),
			profile_file_name: Yup.string().required(t('REQUIRED')),
			profileOffset: Yup.string(),
			profileOffset_file_name: Yup.string(),
			shoreline: Yup.string()
				.required(t('REQUIRED'))
				.test('shoreline', t('REQUIRED'), function (value) {
					const { path, createError } = this;

					const validationResult = validateFileInput({
						inputAlias: 'shoreline',
						profileInputFileType: profileInputFileTypes[2],
						isProfile3D: formik.values.profileDimensions === '3D',
						fileContent: value ?? file.content
					});

					return (
						validationResult.inputValid || createError({ path, message: validationResult.errMsg })
					);
				}),
			shoreline_file_name: Yup.string().required(t('REQUIRED')),
			watercolumn: Yup.string().when('profileDimensions', {
				is: '2D',
				then: Yup.string()
					.required(t('REQUIRED'))
					.test('watercolumn', t('REQUIRED'), function (value) {
						const { path, createError } = this;

						const validationResult = validateFileInput({
							inputAlias: 'shoreline',
							profileInputFileType: profileInputFileTypes[3],
							isProfile3D: formik.values.profileDimensions === '3D',
							fileContent: value ?? file.content
						});

						return (
							validationResult.inputValid || createError({ path, message: validationResult.errMsg })
						);
					})
			}),
			watercolumn_file_name: Yup.string().when('profileDimensions', {
				is: '2D',
				then: Yup.string().required(t('REQUIRED'))
			}),
			profileDimensions: Yup.string().required(t('REQUIRED'))
		}),
		validateOnMount: true,
		initialValues: {
			markers: '',
			markers_file_name: '',
			profile: '',
			profile_file_name: '',
			profileOffset: '',
			profileOffset_file_name: '',
			shoreline: '',
			shoreline_file_name: '',
			watercolumn: '',
			watercolumn_file_name: '',
			profileDimensions: '2D'
		},
		onSubmit: (values) => {
			const submitData = {
				markers: values.markers,
				profile: values.profile,
				shoreline: values.shoreline,
				profile_offset: values.profileOffset,
				watercolumn: values.watercolumn
			};
			ProfileCalibrationService.getCalibrationData(submitData, true)
				.then((res) => {
					setCalibrationImg(res.plot_png_b64, res.profile, res.markers);
				})
				.catch((err) => {
					toast.error(t(err.details));
				});
		}
	});

	useEffect(() => {
		if (formik.values.profileDimensions === '3D') {
			formik.setFieldValue('watercolumn', '');
			formik.setFieldValue('watercolumn_file_name', '');
			if (activeEditFieldIndex === 3) {
				setActiveEditFieldIndex(null);
			}
		}
	}, [formik.values.profileDimensions]);

	const profileInputFileTypes = [
		{
			name: 'profile',
			inputLabel: t('PROFILE'),
			isRequired: true,
			minNumberOfValuesInFile: formik.values.profileDimensions === '2D' ? 6 : 9
		},
		{
			name: 'markers',
			inputLabel: t('MARKERS'),
			isRequired: true,
			minNumberOfValuesInFile: 12,
			maxNumberOfValuesInFile: 60,
			divisableNumberOfValuesInFile: 3
		},
		{
			name: 'shoreline',
			inputLabel: t('SHORELINE'),
			isRequired: true,
			numberOfValuesInFile: 9
		},
		{
			name: 'watercolumn',
			inputLabel: t('WATERCOLUMN'),
			isRequired: formik.values.profileDimensions !== '3D',
			isDisabled: formik.values.profileDimensions === '3D',
			numberOfValuesInFile: 1
		},
		{
			name: 'profileOffset',
			inputLabel: t('PROFILE_OFFSET'),
			isRequired: false,
			numberOfValuesInFile: 1
		}
	];

	const handleUploadClick = (index: number) => {
		const currentInput = document.getElementById(`fileInput${index}`);
		currentInput?.click();
	};
	const readUploadedFile = (name: string, content: string, inputAlias: string) => {
		setFile({ name: name, content: content, inputAlias: inputAlias });
	};

	useEffect(() => {
		if (file.content) {
			formik.setFieldValue(file.inputAlias, file.content);
			formik.setFieldValue(`${file.inputAlias}_file_name`, file.name);
			setTimeout(() => formik.setFieldTouched(file.inputAlias, true), 100);
		}
	}, [file]);

	return (
		<form onSubmit={formik.handleSubmit}>
			<div className={'flex flex-col gap-8 p-4'}>
				{profileInputFileTypes.map((input, index) => {
					return (
						<div
							className={'flex w-full  items-center gap-8 align-middle'}
							key={`profile_input_${index}`}>
							<div className={'w-[60rem] flex-1 flex-col '}>
								<div className={'flex flex-1 flex-row items-baseline'}>
									<TextField
										variant={'standard'}
										className={'w-full'}
										name={`${input.name}_file_name`}
										label={input.inputLabel}
										disabled={true}
										sx={{
											'& .MuiInputBase-input.Mui-disabled': {
												WebkitTextFillColor: '#000000'
											},
											'& .MuiFormLabel-root.Mui-error': {
												color: '#780116 !important'
											},
											'& .MuiInput-underline.Mui-error:after': {
												borderBottomColor: '#780116 !important'
											},
											'& .MuiFormHelperText-root.Mui-error': {
												color: '#780116 !important'
											}
										}}
										onMouseDown={(event) => {
											event.preventDefault();
										}}
										onTouchStart={(event) => {
											event.preventDefault();
										}}
										InputProps={{
											endAdornment: (
												<InsertDriveFileIcon
													sx={{ p: 0.25, color: '#780116', ml: -0.5, mr: 1 }}
													className={'max-xs:hidden'}
												/>
											)
										}}
										onBlur={formik.handleBlur}
										value={
											formik.values[`${input.name}_file_name` as keyof typeof formik.initialValues]
												? formik.values[
														`${input.name}_file_name` as keyof typeof formik.initialValues
												  ]
												: ''
										}
										error={Boolean(
											formik.errors[`${input.name}` as keyof typeof formik.initialValues]
										)}
									/>
									<IconWithTooltip
										icon={
											<IconButton
												disabled={input.isDisabled}
												onClick={() => handleUploadClick(index)}>
												<Upload />
											</IconButton>
										}
										title={t('UPLOAD_FILE')}
									/>
									<IconWithTooltip
										icon={
											<IconButton
												disabled={input.isDisabled}
												onClick={() => {
													if (index === activeEditFieldIndex) setActiveEditFieldIndex(null);
													else setActiveEditFieldIndex(index);
												}}>
												<EditIcon />
											</IconButton>
										}
										title={t('EDIT_CONTENT')}
									/>
								</div>
								<div className={'h-8 text-sm text-danger'}>
									{formik.errors[`${input.name}` as keyof typeof formik.initialValues]}
								</div>
								{activeEditFieldIndex === index && (
									<TextField
										multiline={true}
										rows={input.name === 'watercolumn' ? 1 : 5}
										variant={'outlined'}
										className={'w-full pt-2'}
										name={input.name}
										disabled={input.isDisabled}
										onChange={(e) => {
											if (e.target.value === '') formik.handleChange(e);
											if (scientificNotationNumberPattern.test(e.target.value)) {
												formik.handleChange(e);
												setTimeout(() => formik.setFieldTouched(input.name, true), 100);
												formik.setFieldValue(
													`${input.name}_file_name`,
													e.target.value !== '' ? t('EDITED_CONTENT') : '',
													true
												);
											}
										}}
										onBlur={formik.handleBlur}
										value={formik.values[input.name as keyof typeof formik.initialValues]}
									/>
								)}
							</div>
							<input
								type="file"
								id={`fileInput${index}`}
								className={'hidden'}
								ref={uploadInputRef}
								onChange={(e) => handleFileRead(e, readUploadedFile, input.name)}
								accept="*"
							/>

							<div className={'w-16 md:w-40'}>
								{index === 0 && (
									<RadioGroup
										name="profileDimensions"
										row={true}
										value={formik.values.profileDimensions}
										onChange={formik.handleChange}>
										<FormControlLabel value="2D" control={<Radio />} label={t('2D')} />
										<FormControlLabel value="3D" control={<Radio />} label={t('3D')} />
									</RadioGroup>
								)}
							</div>
						</div>
					);
				})}

				<div className={'flex flex-row justify-center pb-4'}>
					<Button
						variant={'contained'}
						type={'submit'}
						disabled={!formik.isValid || !formik.dirty}
						className={'normal-case'}>
						{t('UPDATE')}
					</Button>
				</div>
			</div>
		</form>
	);
};
export default CrossSectionInput;
