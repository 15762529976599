import { useGlobalStore } from 'global-state/useStore';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Logout from '@mui/icons-material/Logout';
import { useAuth } from 'rq/hooks/authHook';
import { IProfileMenu } from './types';
import { useTranslation } from 'react-i18next';

const ProfileMenu = (props: IProfileMenu) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { data: user } = useAuth();
	const orgId = useGlobalStore((state) => state.currentOrganization.id);

	return (
		<Menu
			anchorEl={props.anchorEl}
			open={props.openUserMenu}
			onClose={props.handleClose}
			onClick={props.handleClose}
			slotProps={{
				paper: {
					elevation: 0,
					className: 'bg-primary',
					sx: {
						overflow: 'visible',
						filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
						mt: 1.5,
						'& .MuiAvatar-root': {
							width: 32,
							height: 32,
							ml: -0.5,
							mr: 1
						},
						'&:before': {
							content: '""',
							display: 'block',
							position: 'absolute',
							top: 0,
							right: 14,
							width: 10,
							height: 10,
							bgcolor: 'background.paper',
							transform: 'translateY(-50%) rotate(45deg)',
							zIndex: 0
						}
					}
				}
			}}
			transformOrigin={{ horizontal: 'right', vertical: 'top' }}
			anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
			<MenuItem onClick={() => navigate(`/${orgId}/users/${user?.id}`)}>
				<Avatar className={'bg-secondary text-primary'} alt="user" src={user?.image} />{' '}
				<div className={'flex flex-col gap-1'}>
					{t('PROFILE')}
					<span className={'text-xs'}>{user?.super_admin && t('YOU_ARE_SUPER_ADMIN')}</span>
				</div>
			</MenuItem>
			<Divider />
			<MenuItem onClick={props.logoutHandler}>
				<ListItemIcon>
					<Logout fontSize="small" />
				</ListItemIcon>
				{t('LOGOUT')}
			</MenuItem>
		</Menu>
	);
};

export default ProfileMenu;
