import { useEffect, useState } from 'react';
import { useGlobalStore } from 'global-state/useStore';
import { DischargeKeeperStationDetails } from 'interfaces/models/DischargeKeeperStationDetails';
import { DischargeStationDetails } from 'interfaces/models/DischargeStationDetails';

const useStationDataFromStore = (
	stationDetails: DischargeStationDetails | DischargeKeeperStationDetails | undefined
) => {
	const updateStationData =
		stationDetails?.station_type === 'DISCHARGE'
			? useGlobalStore((state) => state.setDischargeStationData)
			: useGlobalStore((state) => state.setDischargeKeeperStationData);

	const dischargeStationData = useGlobalStore((state) => state.dischargeStationData);
	const dischargeKeeperStationData = useGlobalStore((state) => state.dischargeKeeperStationData);

	const [stationData, setStationData] = useState(
		stationDetails?.station_type === 'DISCHARGE' ? dischargeStationData : dischargeKeeperStationData
	);

	useEffect(() => {
		setStationData(
			stationDetails?.station_type === 'DISCHARGE'
				? dischargeStationData
				: dischargeKeeperStationData
		);
	}, [stationDetails, dischargeStationData, dischargeKeeperStationData]);

	return { stationData, updateStationData };
};

export default useStationDataFromStore;
