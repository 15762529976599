import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import BlockIcon from '@mui/icons-material/Block';
import UndoIcon from '@mui/icons-material/Undo';
import HomeIcon from '@mui/icons-material/Home';

const AccessDenied: FunctionComponent = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<div className={'flex flex-col items-center justify-evenly'}>
			<BlockIcon className={'h-2/3 w-2/3 text-red-600 md:h-4/6 md:w-4/6 lg:h-1/3 lg:w-1/3 '} />
			<h3 className={'ml-auto ml-4 mb-4 justify-self-center  md:mx-auto md:mb-2'}>
				{t('NOT_AUTHORIZED')}
			</h3>
			<div className={'flex w-2/3 flex-row flex-wrap items-center justify-evenly lg:w-1/3'}>
				<button
					onClick={() => {
						navigate(-1);
					}}>
					<UndoIcon /> {t('GO_BACK')}
				</button>
				<button
					onClick={() => {
						navigate('/');
					}}>
					<HomeIcon /> {t('GO_TO_HOMEPAGE')}
				</button>
			</div>
		</div>
	);
};
export default AccessDenied;
