import { Upload, Warning } from '@mui/icons-material';
import { TextField } from '@mui/material';
import IconWithTooltip from 'components/shared/Tooltip/IconWithTooltip';
import { useFormik } from 'formik';
import { useGlobalStore } from 'global-state/useStore';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { queryKeys } from 'rq/constants';
import { queryClient } from 'rq/queryClient';
import {
	createGCPFormatArray,
	handleFileReadAsString,
	validateMarkerCoordinates
} from 'utils/functions';
import useStationDataFromStore from '../../hooks/useStationDataFromStore';
import PrimaryButton from '../shared/Button/PrimaryButton';

import { scientificNotationNumberPattern } from 'utils/regex';
import { prepareData } from '../../utils/freeProfileParamsHelper';

const GCPCoordinates = () => {
	const { t } = useTranslation();
	const uploadInputRef = useRef<HTMLInputElement | null>(null);
	const [fileContent, setFileContent] = useState<string | null>(null);

	const { stationDetails } = useGlobalStore((state) => state.editGCPModal);
	const { stationData, updateStationData } = useStationDataFromStore(stationDetails ?? undefined);
	const selectedSiteId = useGlobalStore((state) => state.selectedSiteId);

	const [coordinatesValidationError, setCoordinatesValidationError] = useState({
		errorMessage: '',
		isValid: false
	});

	const formik = useFormik({
		initialValues: {
			markers_coordinates: stationData?.markers_coordinates?.join(',') ?? ''
		},
		onSubmit: (values) => {
			const gcpArray = createGCPFormatArray(values.markers_coordinates);

			const station = {
				...stationData,
				markers_coordinates: gcpArray
			};
			updateStationData(station as any);

			toast.success(t('CHANGES_APPLIED'));

			stationData?.station_id &&
				queryClient.invalidateQueries([
					queryKeys.station_details,
					`site-id-${selectedSiteId}`,
					`station-id-${stationData.station_id}`
				]);
			/*				updateStation(
								{
									siteId: Number(selectedSiteId),
									stationId: stationData?.station_id,
									data: station
								},
								{
									onSuccess: () => {
										toast.success(t('STATION_UPDATED'));
										queryClient.invalidateQueries([
											queryKeys.station_details,
											`site-id-${selectedSiteId}`,
											`station-id-${stationData.station_id}`
										]);
									}
								}
							);*/
		}
	});

	useEffect(() => {
		setCoordinatesValidationError(
			validateMarkerCoordinates(prepareData(formik.values.markers_coordinates ?? ''))
		);
	}, [formik.values.markers_coordinates]);

	const handleUploadClick = () => {
		uploadInputRef.current?.click();
	};
	const changeCoordsOnUpload = (coords: string) => {
		const coordinatesParsed = coords.match(scientificNotationNumberPattern) as string[];
		coordinatesParsed && setFileContent(coords);
	};

	useEffect(() => {
		const coordinatesParsed = fileContent?.match(scientificNotationNumberPattern) as string[];
		coordinatesParsed && formik.setFieldValue('markers_coordinates', fileContent);
	}, [fileContent]);

	return (
		<form onSubmit={formik.handleSubmit}>
			<div className={'flex flex-col gap-4'}>
				<div className={'flex w-full flex-row items-center  gap-8 align-middle'}>
					<div className={'flex w-full flex-col gap-2'}>
						<div>{t('MARKER_COORDINATES')}</div>
						<TextField
							multiline={true}
							rows={5}
							variant={'standard'}
							className={'w-full'}
							name="markers_coordinates"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.markers_coordinates}
						/>

						<div className={'h-max pb-2 text-red-500'}>
							{coordinatesValidationError.errorMessage}
						</div>
					</div>
					<input
						type="file"
						className={'hidden'}
						ref={uploadInputRef}
						onChange={(e) => handleFileReadAsString(e, changeCoordsOnUpload)}
						accept="*"
					/>
					<IconWithTooltip icon={<Upload onClick={handleUploadClick} />} title={t('UPLOAD_FILE')} />
				</div>
				<div className={'flex flex-col gap-2 text-sm'}>
					<div className={'flex items-center gap-2 align-middle text-base'}>
						<Warning color={'error'} /> <span className={'font-bold'}>{t('IMPORTANT')}</span>
					</div>
					<div>{t('COORDINATES_WARNING')}</div>
					<div>{t('COORDINATES_INPUT_WARNING')}</div>
				</div>
				<div className={'flex flex-row justify-center pb-4'}>
					<PrimaryButton
						type={'submit'}
						disabled={!coordinatesValidationError.isValid || !formik.dirty}
						className={'normal-case'}>
						{t('UPDATE')}
					</PrimaryButton>
				</div>
			</div>
		</form>
	);
};
export default GCPCoordinates;
