import toast from 'react-hot-toast';
import i18n from 'res/localization/i18n';

export const prepareData = (data: string) => {
	let replaced = data
		.replace('[', '')
		.replace(']', '')
		.replace('h', '')
		.replace('Q', '')
		.replace(/\s+/g, ',')
		.replace(/\t/g, ',')
		.replace(/(\r\n|\n)/g, ',')
		.replace(/,{2,}/g, ',');

	if (replaced[0] === ',') {
		replaced = replaced.substring(1, replaced.length);
	}

	if (replaced[replaced.length - 1] === ',') {
		replaced = replaced.substring(0, replaced.length - 1);
	}
	return replaced;
};

export const parseData = (data: string) => {
	data = prepareData(data);

	const pairs = data.split(',');
	// Swap the first and second elements
	for (let i = 0; i < pairs.length - 1; i += 2) {
		const temp = pairs[i];
		pairs[i] = pairs[i + 1];
		pairs[i + 1] = temp;
	}

	return pairs.map((item) => {
		return parseFloat(item);
	});
};

export const validateInput = (data: string) => {
	// if no data, disable submit
	if (!data) {
		return false;
	}

	data = prepareData(data);

	// if data contains any character other than number, ',' or '.' invalid format
	if (data.match(/[^(,)|(.)|(\-)|(0-9)]/g)) {
		return false;
	}

	const splitData = data.split(',');

	// contains at least 2 pairs
	if (splitData.length < 4) {
		return false;
	}

	if (splitData.length % 2 === 1) {
		return false;
	}

	let hasEmptyItem = false,
		containsNaN = false;
	splitData.forEach((item) => {
		if (item === '') {
			hasEmptyItem = true;
		} else if (isNaN(parseFloat(item))) {
			containsNaN = true;
		}
	});

	return !(hasEmptyItem || containsNaN);
};

export const selectQhRatingCurveFile = (data: string) => {
	if (!validateInput(data as string)) {
		toast.error(i18n.t('HQ_FILE_INVALID_FORMAT'));
		return;
	}

	const selectedData = parseData(data);
	toast.success(i18n.t('HQ_FILE_DATA_IMPORTED'));

	return selectedData;
};
