import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { ISomethingWentWrongProps } from './types';

import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import UndoIcon from '@mui/icons-material/Undo';
import ReplayIcon from '@mui/icons-material/Replay';
import { useTranslation } from 'react-i18next';

const SomethingWentWrong: FunctionComponent<ISomethingWentWrongProps> = ({ error, tryAgain }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	return (
		<>
			<div className={'m-auto flex h-1/2 flex-col'}>
				<ErrorOutlineRoundedIcon className={'h-1/2 w-1/2 self-center text-red-500'} />
				<div className={'mx-auto'}>
					<h3 className={' text-xl'}>{t('SOMETHING_WENT_WRONG')}</h3>
					<h4 className={'text-red-500'}>
						{t('ERROR')}: {error.error.message}
					</h4>
					<div className={'mt-12 flex justify-between'}>
						<button
							onClick={() => {
								navigate(-1);
							}}>
							<UndoIcon /> {t('GO_BACK')}
						</button>
						<button
							onClick={() => {
								tryAgain && tryAgain();
							}}>
							<ReplayIcon /> {t('TRY_AGAIN')}
						</button>
					</div>
				</div>
			</div>
		</>
	);
};
export default SomethingWentWrong;
