import { apiClient } from 'agent/apiClient';
import { ISearchService } from 'services/SearchService/interface';

export const SearchService: ISearchService = {
	searchEntities: async ({ searchTerm, entity, limit = 20 }) => {
		return await apiClient
			.get(`search/${searchTerm}/?entities=${entity}&limit=${limit}`)
			.then((res) => res.data);
	}
};
