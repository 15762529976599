import Layout from 'components/shared/Layout/Layout';
import AuthPagesTheme from 'pages/Auth/AuthPagesTheme';
import JoinOrganizations from 'pages/Auth/JoinOrganizations';
import React, { useEffect } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { useGlobalStore } from 'global-state/useStore';
import { useAuth } from 'rq/hooks/authHook';
import { timezone_display } from 'rq/interfaces/User';
import OrganizationRouter from './OrganizationRouter';

const PrivateRouter = () => {
	const currentOrganization = useGlobalStore((state) => state.currentOrganization);
	const setUserInfo = useGlobalStore((state) => state.setUserInfo);

	const { data: isAuth } = useAuth();

	useEffect(() => {
		isAuth && setUserInfo({ timezone_display: isAuth.timezone_display ?? timezone_display.LOCAL });
	}, [isAuth]);

	const hasOrganizations = isAuth?.organizations && isAuth?.organizations.length > 0;

	return (
		<>
			{hasOrganizations ? (
				<Layout>
					<Routes>
						<Route path="/:orgId/*" element={<OrganizationRouter />} />
						<Route path={'*'} element={<Navigate to={`/${currentOrganization.id}`} replace />} />
					</Routes>
				</Layout>
			) : (
				<div style={{ margin: '0' }}>
					<Routes>
						<Route
							element={
								<AuthPagesTheme>
									<Outlet />
								</AuthPagesTheme>
							}>
							<Route path={'/join-organizations'} element={<JoinOrganizations />} />
							<Route path={'*'} element={<Navigate to="/join-organizations" replace />} />
						</Route>
					</Routes>
				</div>
			)}
		</>
	);
};

export default PrivateRouter;
