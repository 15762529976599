import { UserListRetrieve } from 'interfaces/models/UserListRetrieve';
import { apiClient } from 'agent/apiClient';
import toast from 'react-hot-toast';
import { IUsersService } from './interface';
import { UserCreateUpdate } from 'interfaces/models/UserCreateUpdate';
import { UserImage } from 'interfaces/models/UserImage';
import { MembershipListRetrieve } from 'interfaces/models/MembershipListRetrieve';
import { MembershipCreateUpdateDestroy } from 'interfaces/models/MembershipCreateUpdateDestroy';
import i18n from '../../res/localization/i18n';

const UsersService: IUsersService = {
	getAllUsers: async (search?: string, page?: number, pageSize?: number) => {
		return await apiClient
			.get<{
				count: number;
				next?: string | null;
				previous?: string | null;
				results: Array<UserListRetrieve>;
			}>('users/', {
				params: {
					...(search && { search: search }),
					...(page && { page: page }),
					...(pageSize && { page_size: pageSize })
				}
			})
			.then((res) => {
				Promise.resolve();
				return res.data;
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	},
	fetchUsersGlobal: async (search: string) => {
		return await apiClient
			.get(`users/?search=${search}`, {
				data: 'globalUsersGet'
			})
			.then((res) => {
				return res.data;
			});
	},

	getUserDetails: async (userId) => {
		return await apiClient
			.get<UserListRetrieve>(`users/${userId}/`)
			.then((res) => {
				Promise.resolve();
				return res.data;
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	},

	createUser: async (data) => {
		return await apiClient
			.post<UserCreateUpdate>('users/', data)
			.then((res) => {
				Promise.resolve();
				return res.data;
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	},

	updateUser: async (userId, data) => {
		return await apiClient
			.put<UserCreateUpdate>(`users/${userId}/`, data)
			.then((res) => {
				Promise.resolve();
				return res.data;
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	},

	deleteUser: async (userId) => {
		return await apiClient
			.delete<void>(`users/${userId}/`)
			.then((res) => {
				Promise.resolve();
				return res.data;
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	},

	getUsersImage: async (userId) => {
		return await apiClient
			.get<UserImage>(`users/${userId}/image/`)
			.then((res) => {
				Promise.resolve();
				return res.data;
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	},

	updateUsersImage: async (userId, data) => {
		const formData = new FormData();
		formData.append('image', data.image ?? '');
		return await apiClient
			.put<UserImage>(`users/${userId}/image/`, formData, {
				headers: {
					'content-type': 'multipart/form-data'
				}
			})
			.then((res) => {
				Promise.resolve();
				return res.data;
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	},

	deleteUsersImage: async (userId) => {
		return await apiClient
			.delete<void>(`users/${userId}/image/`)
			.then((res) => {
				Promise.resolve();
				return res.data;
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	},

	getUsersMembershipList: async (userId) => {
		return await apiClient
			.get<MembershipListRetrieve[]>(`/users/${userId}/memberships/`)
			.then((res) => {
				Promise.resolve();
				return res.data;
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	},

	getUsersMembershipDetails: async (membershipId, userId) => {
		return await apiClient
			.get<MembershipListRetrieve>(`/users/${userId}/memberships/${membershipId}/`)
			.then((res) => {
				Promise.resolve();
				return res.data;
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	},

	createUserMembership: async (userId, data) => {
		return await apiClient
			.post<MembershipCreateUpdateDestroy>(`users/${userId}/memberships/`, data)
			.then((res) => {
				Promise.resolve();
				return res.data;
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	},

	updateUserMemberships: async (membershipId, userId, data) => {
		return await apiClient
			.put<MembershipCreateUpdateDestroy>(`users/${userId}/memberships/${membershipId}/`, data)
			.then((res) => {
				Promise.resolve();
				return res.data;
			})
			.catch((err) => {
				return Promise.reject(err);
			});
	},

	deleteUserMembership: async (membershipId, userId) => {
		return await apiClient
			.delete<void>(`users/${userId}/memberships/${membershipId}/`)
			.then((res) => {
				toast.success(i18n.t('USER_REMOVED_FROM_ORG'));
				Promise.resolve();
				return res.data;
			})
			.catch((err) => {
				toast.error(err.message);
				return Promise.reject(err);
			});
	}
};
export default UsersService;
