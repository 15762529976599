import { Edit, Help, Settings, SettingsSuggest, Visibility } from '@mui/icons-material';
import { Autocomplete, Button, MenuItem, Popper, Select, TextField } from '@mui/material';
import classNames from 'classnames';
import PermissionView from 'components/shared/PermissionsView/PermissionView';
import { useFormik } from 'formik';
import { useGlobalStore } from 'global-state/useStore';
import useStationInfo from 'hooks/useStationDetailsHook';
import { DischargeKeeperStationDetails } from 'interfaces/models/DischargeKeeperStationDetails';
import { IStationFormProps } from 'pages/SiteDetails/components/StationTabs/components/types';
import React, { FunctionComponent, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { queryKeys } from 'rq/constants';
import { useCreateStation, useStationDetails, useUpdateStation } from 'rq/hooks/stationHook';
import { queryClient } from 'rq/queryClient';
import StationService from 'services/StationService/StationService';
import { profileTypes, roughnessCategories } from 'utils/helperData';
import * as Yup from 'yup';
import { hasValueProperty } from 'utils/functions';
import PrimaryButton from 'components/shared/Button/PrimaryButton';
import { isEqual } from 'helper/helperFunctions';
import CancelButton from '../../../../../components/shared/Button/CancelButton';
import { PROCESSING_STEPS } from '../../../../CloudProcessing/ProcessingSteps';
import IconWithTooltip from '../../../../../components/shared/Tooltip/IconWithTooltip';

const DischargeKeeperStationForm: FunctionComponent<IStationFormProps> = ({
	station_id,
	CPFastFlowForm,
	showOriginalConfigData
}) => {
	const { t } = useTranslation();
	const { siteId } = useParams();
	const toggleCreateStationModal = useGlobalStore((state) => state.toggleCreateStationModal);
	const selectedSiteId = useGlobalStore((state) => state.selectedSiteId);
	const currentOrganization = useGlobalStore((state) => state.currentOrganization);
	const toggleEditGCPModal = useGlobalStore((state) => state.toggleEditGCPModal);
	const toggleSetUpCrossSectionModal = useGlobalStore(
		(state) => state.toggleSetUpCrossSectionModal
	);
	const toggleFreeProfileChartModal = useGlobalStore((state) => state.toggleFreeProfileChartModal);
	const toggleEditFreeProfilePointsModal = useGlobalStore(
		(state) => state.toggleEditFreeProfilePointsModal
	);
	const toggleEditAdvancedConfig = useGlobalStore((state) => state.toggleEditAdvancedConfig);
	const toggleConfirmationDialog = useGlobalStore((state) => state.toggleConfirmationDialog);

	const stationData = useGlobalStore((state) => state.dischargeKeeperStationData);
	const setStationData = useGlobalStore((state) => state.setDischargeKeeperStationData);
	const setStationDataApplied = useGlobalStore((state) => state.setStationChangesApplied);
	const stationDataApplied = useGlobalStore((state) => state.stationChangesApplied);
	const stationDataChanged = useGlobalStore((state) => state.stationDataChanged);
	const setStationDataChanged = useGlobalStore((state) => state.setStationDataChanged);

	const currentStep = useGlobalStore((state) => state.currentStep);
	const displayStep = useGlobalStore((state) => state.displayStep);
	const setCurrentStep = useGlobalStore((state) => state.setCurrentStep);
	const setDisplayStep = useGlobalStore((state) => state.setDisplayStep);

	const [submitAction, setSubmitAction] = useState<'apply' | 'update'>('update');

	const { mutate: createStation } = useCreateStation();
	const { mutate: updateStation } = useUpdateStation();
	const { data: stationDetails } = useStationDetails<DischargeKeeperStationDetails>(
		Number(siteId),
		station_id,
		{
			enabled: !!station_id
		}
	);
	useEffect(() => {
		stationDetails && !stationData && setStationData(stationDetails, true);
	}, [stationDetails]);

	const { manageStationPermission, GCPPointsDefined } = useStationInfo(
		currentOrganization,
		stationData as DischargeKeeperStationDetails
	);

	const [stationFormData, setStationFormData] = useState(stationData);
	useEffect(() => {
		if (showOriginalConfigData) {
			setStationFormData(stationDetails as DischargeKeeperStationDetails);
		} else {
			setStationFormData(stationData);
		}
	}, [showOriginalConfigData, stationData]);

	const validationSchema = Yup.object().shape({
		profile: Yup.object().shape({
			type: Yup.string().required(t('REQUIRED')),
			depth: Yup.number()
				.min(0)
				.required()
				.when('profile.type', {
					is: (profileType: string) => profileType !== 'FREE',
					then: Yup.number().required(t('REQUIRED'))
				}),
			roughness: Yup.number().required().min(20, t('ROUGHNESS_MIN_VALUE_WARNING')),
			width: Yup.number()
				.min(0)
				.required()
				.when('profile.type', {
					is: (profileType: string) => profileType !== 'FREE',
					then: Yup.number().required(t('REQUIRED'))
				}),
			bottom_width: Yup.number()
				.min(0)
				.required()
				.when('profile.type', {
					is: (profileType: string) => profileType === 'TRAPEZOIDAL',
					then: Yup.number().required(t('REQUIRED'))
				})
		}),
		video_resolution: Yup.string().required(t('REQUIRED'))
	});

	const formik = useFormik({
		initialValues: {
			station_name: stationFormData?.station_name ?? null,
			station_type: stationFormData?.station_type ?? 'DISCHARGE_KEEPER',
			image: stationFormData?.image ?? '',
			profile: {
				type: stationFormData?.profile.type ?? 'FREE',
				depth: stationFormData?.profile.depth ?? 0,
				roughness: stationFormData?.profile.roughness || 20,
				width: stationFormData?.profile.width ?? 0,
				bottom_width: stationFormData?.profile.bottom_width ?? 0,
				free_params: stationFormData?.profile.free_params ?? []
			},
			markers_coordinates: stationFormData?.markers_coordinates ?? [],
			marker_measurements: stationFormData?.markers_measurements ?? [],
			video_resolution: stationFormData?.video_resolution ?? 720,
			camera_position:
				stationFormData?.camera_position && stationFormData?.camera_position?.length > 0
					? stationFormData?.camera_position
					: [0],
			camera_angle: stationFormData?.camera_angle ?? 0,
			camera_rotation:
				stationFormData?.camera_rotation && stationFormData?.camera_rotation?.length > 0
					? stationFormData?.camera_rotation
					: [0],
			deployment_status: stationFormData?.deployment_status ?? 'DRAFT'
		},
		validationSchema: validationSchema,
		onSubmit: (data) => {
			if (data.profile.type === 'FREE') {
				data.profile.width = 0;
				data.profile.depth = 0;
				data.profile.bottom_width = 0;
			} else if (data.profile.type !== 'TRAPEZOIDAL') {
				data.profile.bottom_width = 0;
			}

			if (data.profile.type !== 'FREE') {
				data.profile.free_params = [];
			}

			if (
				!CPFastFlowForm ||
				(CPFastFlowForm &&
					currentStep.name === 'RESULTS' &&
					displayStep.name === 'RESULTS' &&
					submitAction === 'update')
			) {
				station_id &&
					updateStation(
						{
							siteId: Number(selectedSiteId),
							stationId: station_id,
							data: data
						},
						{
							onSuccess: () => {
								toast.success(t('STATION_UPDATED'));
								queryClient.invalidateQueries([
									queryKeys.station_details,
									`site-id-${selectedSiteId}`
								]);
							}
						}
					);
				!station_id &&
					createStation(
						{
							siteId: Number(selectedSiteId),
							data: data
						},
						{
							onSuccess: () => {
								toast.success(t('STATION_UPDATED'));
								toggleCreateStationModal();
								queryClient.invalidateQueries([queryKeys.stations, `site-${selectedSiteId}`]);
							}
						}
					);
			} else {
				setStationData({ station_id, ...data });
				setStationDataApplied(true);
				if (currentStep.name === 'WATER_LEVEL' || currentStep.name === 'RESULTS') {
					setCurrentStep(PROCESSING_STEPS[1]);
					setDisplayStep(PROCESSING_STEPS[1]);
				}
			}
		},
		enableReinitialize: true
	});

	const resetFormData = () => {
		if (stationDetails) {
			formik.resetForm();
			if (currentStep.name === 'WATER_LEVEL' || currentStep.name === 'RESULTS') {
				setCurrentStep(PROCESSING_STEPS[1]);
				setDisplayStep(PROCESSING_STEPS[1]);
			}
			setStationData(stationDetails);
		}
	};

	useEffect(() => {
		setStationDataChanged(!isEqual(formik.values, formik.initialValues));
		setStationDataApplied(isEqual(formik.values, formik.initialValues));
	}, [formik.values, formik.initialValues]);

	return (
		<>
			<form onSubmit={formik.handleSubmit} autoComplete={'off'}>
				<div className={'mt-4 flex flex-col gap-10 md:gap-5'}>
					<div className={'flex w-full flex-col lg:flex-row'}>
						<div
							className={'flex items-center align-middle max-md:text-lg max-md:font-bold md:w-60'}>
							{t('PROFILE')}
						</div>
						<div className={'flex w-full gap-4 normal-case max-md:flex-col'}>
							<Select
								className={'h-10 w-full max-w-[15rem]'}
								name={'profile.type'}
								value={formik.values.profile?.type ?? 'FREE'}
								onChange={formik.handleChange}
								disabled={!manageStationPermission}
								onBlur={formik.handleBlur}
								error={formik.touched.profile?.type && Boolean(formik.errors.profile?.type)}>
								{profileTypes.map((profileType) => (
									<MenuItem value={profileType} key={profileType}>
										<span>
											{profileType.toLowerCase().charAt(0).toUpperCase() +
												profileType.toLowerCase().slice(1)}
										</span>
									</MenuItem>
								))}
							</Select>
							<div
								className={
									'flex items-center gap-3 rounded-lg border border-gray-400 bg-neutral-200 px-4 align-middle text-xs max-md:mt-2 max-md:py-2 max-sm:w-[15rem] max-sm:flex-col md:h-full'
								}>
								{formik.values.profile.type !== 'FREE' ? (
									<>
										<span>{t('WIDTH_M')}</span>
										<TextField
											variant={'standard'}
											className={'h-8 text-sm md:max-w-[5rem]'}
											type={'number'}
											required={true}
											name={'profile.width'}
											value={formik.values.profile?.width}
											InputProps={{
												inputProps: { min: 0 },
												style: { fontSize: 'medium' }
											}}
											onChange={formik.handleChange}
											disabled={!manageStationPermission}
											onBlur={formik.handleBlur}
											error={formik.touched.profile?.width && Boolean(formik.errors.profile?.width)}
										/>
										<div>{t('DEPTH_M')}</div>
										<TextField
											variant={'standard'}
											className={'h-8 md:max-w-[5rem]'}
											type={'number'}
											name={'profile.depth'}
											value={formik.values.profile?.depth}
											InputProps={{
												inputProps: { min: 0 }
											}}
											onChange={formik.handleChange}
											disabled={!manageStationPermission}
											onBlur={formik.handleBlur}
											error={formik.touched.profile?.depth && Boolean(formik.errors.profile?.depth)}
										/>
										{formik.values.profile.type === 'TRAPEZOIDAL' && (
											<>
												{' '}
												<span>{t('BOTTOM_WIDTH_M')}</span>
												<TextField
													variant={'standard'}
													className={'h-8 w-auto md:max-w-[5rem]'}
													type={'number'}
													name={'profile.bottom_width'}
													value={formik.values.profile?.bottom_width}
													InputProps={{
														inputProps: { min: 0 }
													}}
													onChange={formik.handleChange}
													disabled={!manageStationPermission}
													onBlur={formik.handleBlur}
													error={
														formik.touched.profile?.bottom_width &&
														Boolean(formik.errors.profile?.bottom_width)
													}
												/>
											</>
										)}
									</>
								) : (
									<>
										<div className={'flex w-full items-center gap-3 align-middle'}>
											<div className={'flex h-full flex-row flex-wrap gap-6 md:p-2'}>
												<div
													className={classNames(
														{
															'flex items-center gap-2 align-middle ': true
														},
														{ 'cursor-pointer hover:opacity-50': !!stationDetails }
													)}
													onClick={() => {
														setStationData({ station_id, ...formik.values });
														stationDetails &&
															toggleFreeProfileChartModal({
																open: true,
																station_id: stationDetails?.station_id
															});
													}}>
													<Visibility className={'text-gray-500'} /> {t('PREVIEW')}
												</div>
												<PermissionView
													requiredPermissions={['manage_sites']}
													showFallbackComponent={false}>
													<>
														<div
															className={classNames(
																{
																	'flex items-center gap-2 align-middle': true
																},
																{ 'cursor-pointer hover:opacity-50': !!stationDetails }
															)}
															onClick={() => {
																setStationData({ station_id, ...formik.values });
																stationDetails &&
																	toggleSetUpCrossSectionModal({
																		open: true,
																		station_id: stationDetails.station_id
																	});
															}}>
															<Settings className={'text-gray-500'} /> {t('CONFIGURE')}
														</div>

														<div
															className={classNames(
																{
																	' flex items-center gap-2 align-middle': true
																},
																{ 'cursor-pointer hover:opacity-50': !!stationDetails }
															)}
															onClick={() => {
																setStationData({ station_id, ...formik.values });
																stationDetails &&
																	toggleEditFreeProfilePointsModal({
																		open: true,
																		station_id: stationDetails.station_id
																	});
															}}>
															<Edit className={'text-gray-500'} /> {t('EDIT')}
														</div>
													</>
												</PermissionView>
											</div>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
					<div className={'flex h-10 w-full max-md:flex-col'}>
						<div className={'flex items-center max-md:text-lg max-md:font-bold md:w-60'}>
							{t('GCP')}
						</div>
						<div className={'flex w-full max-md:flex-col'}>
							<div className={'mr-4 flex h-full w-full max-w-[10rem] items-center align-middle'}>
								{GCPPointsDefined
									? `${GCPPointsDefined} ${t('POINTS_DEFINED')}`
									: t('NO_POINTS_DEFINED')}
							</div>
							<PermissionView requiredPermissions={['manage_sites']} showFallbackComponent={false}>
								<div className={'flex h-full flex-row flex-wrap gap-2 md:p-2'}>
									{/*	<div className={'mr-4 flex cursor-pointer items-center gap-2 align-middle'}>
									<Visibility className={'text-gray-500'} /> {t('PREVIEW')}
								</div>*/}
									<div
										className={'flex cursor-pointer items-center gap-2 align-middle'}
										onClick={() => {
											setStationData({ station_id, ...formik.values });
											stationDetails &&
												toggleEditGCPModal({
													open: true,
													station_id: stationDetails.station_id,
													stationDetails: stationDetails
												});
										}}>
										<Settings className={'text-gray-500'} /> {t('CONFIGURE')}
									</div>
								</div>
							</PermissionView>
						</div>
					</div>
					<div className={'flex w-full max-md:flex-col md:h-full'}>
						<div className={'flex items-center gap-2 max-md:text-lg max-md:font-bold md:w-60'}>
							{t('ROUGHNESS')}
						</div>
						<div className={'flex w-full  items-center gap-2 '}>
							<Autocomplete
								freeSolo={true}
								options={roughnessCategories}
								groupBy={(option) => option.group}
								getOptionLabel={(option) =>
									hasValueProperty(option)
										? option.value.toString()
										: formik.values.profile.roughness.toString()
								}
								renderOption={(props, option) => {
									return (
										<span {...props} key={`${option.name} - ${option.value}`}>
											{t(option.name) + ` (${option.displayValue})`}
										</span>
									);
								}}
								value={
									roughnessCategories.find(
										(option) => option.value === formik.values.profile.roughness
									) ?? formik.values.profile.roughness?.toString()
								}
								className={'h-10 w-full max-w-[15rem]'}
								onBlur={formik.handleBlur}
								onChange={(e, newValue: any) => {
									formik.setFieldValue(
										'profile.roughness',
										!isNaN(Number(newValue?.value)) ? Number(newValue?.value) : Number(newValue)
									);
								}}
								disabled={!manageStationPermission}
								renderInput={(params) => (
									<TextField
										{...params}
										name={'profile.roughness'}
										className={'w-full max-w-[15rem]'}
										size={'small'}
										onChange={(e) =>
											formik.setFieldValue('profile.roughness', Number(e.target.value))
										}
										disabled={!manageStationPermission}
										value={formik.values.profile?.roughness.toString()}
									/>
								)}
								renderGroup={(params) => (
									<li key={params.key} className={'flex flex-col gap-2'}>
										<div className={'px-2 font-bold'}>{t(params.group)}</div>
										<div>{params.children}</div>
									</li>
								)}
								PopperComponent={(props) => (
									<Popper {...props} style={{ width: 'fit-content' }} placement={'bottom-start'} />
								)}
							/>
							<div className="h-4 text-xs text-red-600">{formik.errors.profile?.roughness}</div>
							{/*	<Help className={'text-gray-500'}></Help>*/}
						</div>
					</div>
					{!CPFastFlowForm && (
						<>
							<PermissionView requiredPermissions={['manage_sites']} showFallbackComponent={false}>
								<>
									<div className={'flex h-10 w-full items-center align-middle max-md:flex-col'}>
										<div className={'flex items-center max-md:text-lg max-md:font-bold md:w-60'}>
											{t('ADVANCED_CONFIGURATION')}
										</div>
										<div className={'w-full'}>
											<div className={'flex cursor-pointer items-center gap-2 align-middle'}>
												<SettingsSuggest
													className={'text-gray-500'}
													onClick={() => toggleEditAdvancedConfig(true, Number(station_id))}
												/>
												<span className={'cursor-auto'}>{t('CONFIGURE')}</span>{' '}
												{/*<Help className={'text-gray-500'}></Help>*/}
											</div>
										</div>
									</div>
									<div></div>
								</>
							</PermissionView>
						</>
					)}
					{!showOriginalConfigData && (
						<div className={'self-center'}>
							{!CPFastFlowForm ? (
								<PermissionView
									requiredPermissions={['manage_sites']}
									showFallbackComponent={false}>
									<div className={'mt-4 flex w-fit justify-center gap-4'}>
										<Button
											onClick={() => {
												setSubmitAction('update');
												formik.handleSubmit();
											}}
											disabled={
												station_id
													? !formik.isValid ||
													  !manageStationPermission ||
													  (!formik.dirty &&
															JSON.stringify(stationData) === JSON.stringify(stationDetails))
													: !manageStationPermission
											}
											variant={'contained'}
											className={'w-fit bg-secondary text-primary disabled:bg-gray-500'}>
											{station_id ? t('UPDATE') : t('CREATE')}
										</Button>

										{station_id && stationDetails?.deployment_status !== 'NONE' && (
											<Button
												disabled={stationDetails?.deployment_status !== 'DRAFT'}
												variant={'contained'}
												onClick={() =>
													StationService.deployDischargeKeeperConfig({
														site_id: Number(selectedSiteId),
														station_id: Number(station_id)
													}).then(() => {
														toast.success('Success');
														queryClient.invalidateQueries([
															queryKeys.station_details,
															`site-id-${selectedSiteId}`,
															`station-id-${station_id}`
														]);
													})
												}
												className={'bg-accent text-primary disabled:bg-gray-500'}>
												{stationDetails?.deployment_status === 'DRAFT'
													? t('DEPLOY')
													: t(stationDetails?.deployment_status ?? '')}
											</Button>
										)}
										<CancelButton
											onClick={() => resetFormData()}
											className={'w-fit'}
											disabled={
												isEqual(formik.values.profile, stationDetails?.profile) &&
												isEqual(
													formik.values.markers_coordinates,
													stationDetails?.markers_coordinates
												) &&
												!stationDataChanged
											}>
											{t('RESET')}
										</CancelButton>
									</div>
								</PermissionView>
							) : (
								<div className={'flex w-full flex-wrap justify-between gap-2 self-start '}>
									<div className={' flex gap-2'}>
										<PrimaryButton
											className={'w-fit'}
											onClick={() => {
												toggleConfirmationDialog({
													type: 'confirmation',
													confirmAction: () => {
														setSubmitAction('apply');
														formik.handleSubmit();
													},
													dialogOpen: true,
													headerTitle: t('RECONFIGURE'),
													message:
														displayStep.name === 'CAMERA_CALIBRATION'
															? t('STATION_FINE_TUNE_GCP_TIP')
															: t('STATION_FINE_TUNE_TIP'),
													confirmActionLabel: t('RECONFIGURE'),
													cancelActionLabel: t('CANCEL')
												});
												/*			setSubmitAction('apply');
															formik.handleSubmit();*/
											}}
											disabled={
												(isEqual(formik.values.profile, stationDetails?.profile) &&
													isEqual(
														formik.values.markers_coordinates,
														stationDetails?.markers_coordinates
													) &&
													!stationDataChanged) ||
												stationDataApplied
											}>
											{t('RECONFIGURE')}
										</PrimaryButton>
										<CancelButton
											onClick={() => {
												toggleConfirmationDialog({
													type: 'confirmation',
													confirmAction: () => {
														resetFormData();
													},
													dialogOpen: true,
													headerTitle: t('RESET'),
													message: t('RESET_STATION_CONFIRMATION'),
													confirmActionLabel: t('RESET'),
													cancelActionLabel: t('CANCEL')
												});
											}}
											className={'w-fit'}
											disabled={
												isEqual(formik.values.profile, stationDetails?.profile) &&
												isEqual(
													formik.values.markers_coordinates,
													stationDetails?.markers_coordinates
												) &&
												!stationDataChanged
											}>
											{t('RESET')}
										</CancelButton>
										<IconWithTooltip
											title={
												displayStep.name === 'CAMERA_CALIBRATION'
													? t('STATION_FINE_TUNE_GCP_TIP')
													: t('STATION_FINE_TUNE_TIP')
											}
											icon={<Help className={'text-gray-500'} />}
										/>
									</div>
									{currentStep.name === 'RESULTS' && displayStep.name === 'RESULTS' && (
										<PrimaryButton
											onClick={() => {
												setSubmitAction('update');
												formik.handleSubmit();
											}}
											disabled={
												station_id
													? !formik.isValid ||
													  !manageStationPermission ||
													  (!formik.dirty && isEqual(stationDetails, stationData))
													: !manageStationPermission
											}
											className={'bg-secondary  text-primary disabled:bg-gray-300'}>
											{station_id ? t('UPDATE_STATION') : t('CREATE')}
										</PrimaryButton>
									)}
								</div>
							)}
						</div>
					)}
				</div>
			</form>
		</>
	);
};
export default DischargeKeeperStationForm;
