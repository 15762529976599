import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ isAllowed, children }: { isAllowed: boolean; children: any }) => {
	if (!isAllowed) {
		return <Navigate to="/" replace />;
	}

	return children;
};
export default ProtectedRoute;
