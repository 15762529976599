import { GlobalSiteSearchResult } from 'helper/types';
import { useQuery, UseQueryOptions } from 'react-query';
import { queryKeys } from 'rq/constants';
import { SearchService } from 'services/SearchService/SearchService';

interface GlobalSearchData {
	searchTerm: string;
	entity: string;
	limit?: number;
}

export const useGlobalSearch = (
	data: GlobalSearchData,
	options: Omit<
		UseQueryOptions<GlobalSiteSearchResult, unknown, GlobalSiteSearchResult, any>,
		'queryKey' | 'queryFn'
	>
) => {
	return useQuery(
		[queryKeys.globalSearch, { searchTerm: data.searchTerm }],
		() => SearchService.searchEntities(data).then((res) => res),
		{ ...options }
	);
};
