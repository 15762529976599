import { apiClient } from 'agent/apiClient';
import toast from 'react-hot-toast';
import { IAlarmsService } from 'services/AlarmsService/interface';
import i18n from 'res/localization/i18n';

const AlarmsService: IAlarmsService = {
	alarmsRead: async () => {
		return await apiClient.get('/alarms/').then((res) => {
			return res.data;
		});
	},
	alarmCreate: async (data) => {
		return await apiClient
			.post('/alarms/', data)
			.then((res) => {
				return res.data;
			})
			.catch((error) => toast.error(`${i18n.t('ERROR')} ${error.status} - ${error.statusText}`));
	},
	alarmDelete: async (alarmId) => {
		return await apiClient.delete(`/alarms/${alarmId}/`);
	},
	alarmUpdate: async (alarmId, data) => {
		return await apiClient.put(`/alarms/${alarmId}/`, data).then((res) => {
			return res.data;
		});
	}
};
export default AlarmsService;
