import React, { FunctionComponent } from 'react';
import { IButtonLinkProps } from './types';
import { useTranslation } from 'react-i18next';
import { AlarmAdd } from '@mui/icons-material';

const AddAlarmButton: FunctionComponent<IButtonLinkProps> = ({ handleClick }) => {
	const { t } = useTranslation();

	return (
		<>
			<div className={'flex flex-row gap-2 align-middle'}>
				<AlarmAdd className={'text-gray-500'}></AlarmAdd>
				<a className={' cursor-pointer text-secondary underline'} onClick={handleClick}>
					{t('ADD_ALARM')}
				</a>
			</div>
		</>
	);
};
export default AddAlarmButton;
