import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './en.json';
import translationDE from './de.json';
import translationRU from './ru.json';
import translationES from './es.json';
import translationCH from './ch.json';
import translationAR from './ar.json';
import translationFR from './fr.json';
import translationIT from './it.json';

// the translations
const resources = {
	en: {
		translation: translationEN
	},
	de: {
		translation: translationDE
	},
	ru: {
		translation: translationRU
	},
	es: {
		translation: translationES
	},
	ch: {
		translation: translationCH
	},
	ar: {
		translation: translationAR
	},
	fr: {
		translation: translationFR
	},
	it: {
		translation: translationIT
	}
};

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: localStorage.getItem('lng') ?? 'en',

		keySeparator: false, // we do not use keys in form messages.welcome

		interpolation: {
			escapeValue: false // react already safes from xss
		}
	});

export default i18n;

export const availableTranslations = [
	{ language: 'ENGLISH_LANGUAGE', short: 'en', flag: 'gb' },
	{ language: 'RUSSIAN_LANGUAGE', short: 'ru', flag: 'ru' },
	{ language: 'FRENCH_LANGUAGE', short: 'fr', flag: 'fr' },
	{ language: 'GERMAN_LANGUAGE', short: 'de', flag: 'de' },
	{ language: 'SPANISH_LANGUAGE', short: 'es', flag: 'es' },
	{ language: 'ARABIC_LANGUAGE', short: 'ar', flag: 'ae' },
	{ language: 'CHINESE_LANGUAGE', short: 'ch', flag: 'cn' },
	{ language: 'ITALIAN_LANGUAGE', short: 'it', flag: 'it' }
];
