import { Download, OpenInNew } from '@mui/icons-material';
import { List, ListItem } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from 'components/shared/Modal/Modal';
import IconWithTooltip from 'components/shared/Tooltip/IconWithTooltip';
import { useGlobalStore } from 'global-state/useStore';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshotConfigHistory } from 'rq/hooks/stationHook';
import { fetchJsonFromUrl } from 'utils/functions';

const ConfigHistoricalOverviewModal = () => {
	const { t } = useTranslation();
	const { open, stationId, siteId } = useGlobalStore((state) => state.configHistoricalOverview);
	const toggleConfigHistoricalOverview = useGlobalStore(
		(state) => state.toggleConfigHistoricalOverview
	);

	const { data: snapshotConfigHistory, isLoading: configLoading } = useSnapshotConfigHistory(
		Number(siteId),
		Number(stationId)
	);
	const extractFileNameFromURL = (url: string) => {
		const parsedUrl = new URL(url);
		const pathname = parsedUrl.pathname;

		// Extract the last segment of the pathname
		return pathname.split('/').pop();
	};

	const handleClose = () => {
		toggleConfigHistoricalOverview(false, null, null);
	};

	const handleFileDownload = (url: string) => {
		if (url) {
			fetchJsonFromUrl(url)
				.then((response) => {
					const jsonStr = JSON.stringify(response, null, 2);
					const blob = new Blob([jsonStr], { type: 'application/json' });
					const downloadUrl = URL.createObjectURL(blob);

					const link = document.createElement('a');
					link.href = downloadUrl;
					link.download = extractFileNameFromURL(url) ?? 'download.json';
					document.body.appendChild(link);
					link.click();

					link.addEventListener('click', () => URL.revokeObjectURL(downloadUrl), { once: true });
				})
				.catch((error) => console.error('There was a problem with your axios operation:', error));
		}
	};

	return (
		<Modal closeModal={handleClose} modalTitle={t('CONFIG_HISTORICAL_OVERVIEW')} open={open}>
			<div>
				{configLoading ? (
					<div
						className={'flex h-full flex-col items-center justify-center gap-2 py-4 align-middle'}>
						<CircularProgress />
						<div>{t('LOADING')}</div>
					</div>
				) : snapshotConfigHistory && snapshotConfigHistory?.length > 0 ? (
					<List>
						{snapshotConfigHistory?.map((config) => (
							<ListItem key={config}>
								<div className={'flex w-full justify-evenly gap-2'}>
									<div className={'w-4/5 text-lg font-bold sm:w-2/5'}>
										{extractFileNameFromURL(config)}
									</div>
									<div className={'flex gap-4'}>
										<IconWithTooltip
											icon={<Download onClick={() => handleFileDownload(config)} />}
											title={t('DOWNLOAD')}></IconWithTooltip>

										<IconWithTooltip
											icon={
												<a href={config} target={'_blank'} rel="noreferrer noopener">
													<OpenInNew />
												</a>
											}
											title={t('OPEN_IN_NEW_TAB')}></IconWithTooltip>
									</div>
								</div>
							</ListItem>
						))}
					</List>
				) : (
					<div className={'flex h-20 items-center justify-center'}>{t('NO_DATA_TO_DISPLAY')}</div>
				)}
			</div>
		</Modal>
	);
};
export default ConfigHistoricalOverviewModal;
