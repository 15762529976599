import { getCountries } from 'helper/requests';
import { useState, useEffect } from 'react';

const useCountries = () => {
	const [countries, setCountries] = useState<
		{
			image: HTMLImageElement;
			code: string;
			country_zone_by_income: string;
			flag: string;
			name: string;
		}[]
	>([]);

	useEffect(() => {
		const fetchCountries = async () => {
			try {
				const response = await getCountries(); // Assuming getCountries is a function that fetches country data
				const countriesWithImages = await Promise.all(
					response.map(async (country) => {
						const img = new Image();
						img.src = country.flag;
						await img.decode();
						return { ...country, image: img };
					})
				);
				setCountries(countriesWithImages);
			} catch (error) {
				console.error('Error fetching country data:', error);
			}
		};

		fetchCountries();
	}, []);

	return countries;
};

export default useCountries;
