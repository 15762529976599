import { OrganizationListRetrieve } from "./Organization";

export type UserListRetrieve = {
  readonly id?: number;
  /* Required. 30 characters or fewer. Letters, digits and ._- only. */
  readonly username?: string;
  readonly email?: string;
  readonly first_name?: string;
  readonly last_name?: string;
  readonly super_admin?: boolean;
  readonly image?: string;
  readonly date_joined?: string;
  readonly timezone_display?: timezone_display | null;
  readonly organizations?: Array<OrganizationListRetrieve>;
  readonly display_language?: display_language;
  readonly newsletter?: boolean;
  readonly accepted_tac?: boolean;
  readonly legal_age?: boolean;
};

export enum timezone_display {
  SITE = "SITE",
  LOCAL = "LOCAL",
  UTC = "UTC",
}

export enum display_language {
  EN = "en",
  RU = "ru",
  ES = "es",
  FR = "fr",
  DE = "de",
  AR = "ar",
  CH = "ch",
}
