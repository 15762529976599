import { useGlobalStore } from 'global-state/useStore';
import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';

const theme = createTheme();

interface IAuthPagesThemeProps {
	children: React.ReactNode;
}

const AuthPagesTheme = (props: IAuthPagesThemeProps) => {
	const { isSeba } = useGlobalStore((state) => state.hostname);
	return (
		<ThemeProvider theme={theme}>
			<Grid className="flex flex-row" container component="main" sx={{ height: '100vh' }}>
				<CssBaseline />
				<Grid
					item
					xs={false}
					sm={4}
					md={7}
					className={isSeba ? 'bg-primary' : 'bg-discharge'}
					sx={{
						backgroundImage: isSeba
							? 'url(/assets/seba-vector-logo-blue.svg)'
							: 'url(/assets/Discharge_logotip.svg)',
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'fit',
						backgroundPosition: 'center'
					}}
				/>
				{props.children}
			</Grid>
		</ThemeProvider>
	);
};

export default AuthPagesTheme;
