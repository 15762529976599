import React, { Suspense, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGlobalStore } from 'global-state/useStore';
import { useAuth } from 'rq/hooks/authHook';
import { ErrorBoundary } from 'react-error-boundary';
import SomethingWentWrong from '../components/Error/SomethingWentWrong';
import ProtectedRoute from './ProtectedRoute';
import { OrganizationsService } from 'services/OrganizationsService/OrganizationService';
import { OrganizationListRetrieve } from 'interfaces/models/OrganizationListRetrieve';
import CircularLoadingFullHeight from '../components/shared/LoadingIndicators/CircularLoadingFullHeight';

// Lazy load components
const MapPage = React.lazy(() => import('../pages/Map/MapPage'));
const SitesPage = React.lazy(() => import('../pages/Sites/SitesPage'));
const SiteDetailsPage = React.lazy(() => import('../pages/SiteDetails/SiteDetails'));
const MeasurementsPage = React.lazy(() => import('../pages/MeasurementsPage/MeasurementsPage'));
const ProfilePage = React.lazy(() => import('../pages/ProfilePage/ProfilePage'));
const UsersPage = React.lazy(() => import('../pages/Users/UsersPage'));
const OrganizationPage = React.lazy(() => import('../pages/Organization/OrganizationPage'));
const Alarms = React.lazy(() => import('../pages/Alarms/Alarms'));
const CloudProcessingHome = React.lazy(
	() => import('../pages/CloudProcessing/CloudProcessingHome')
);
const CloudProcessing = React.lazy(() => import('../pages/CloudProcessing/CloudProcessing'));

const OrganizationRouter = () => {
	const [loading, setLoading] = useState(true);
	const location = useLocation();
	const locationState = location.state as { orgName: string } | undefined;
	const params = useParams();
	const navigate = useNavigate();
	const { error } = useGlobalStore((state) => state.joinOrRedirectOrganizationModal);
	const toggleJoinOrRedirectOrganizationModal = useGlobalStore(
		(state) => state.toggleJoinOrRedirectOrganizationModal
	);
	const { data: user } = useAuth();

	useEffect(() => {
		setLoading(true);
		const localstorageOrgId = Number(localStorage.getItem('currentOrganizationId'));
		const paramId = Number(params.orgId);
		if (isNaN(paramId)) {
			navigate(`/${localstorageOrgId}`);
		} else {
			if (
				user?.super_admin === true ||
				(user?.organizations?.some((org: OrganizationListRetrieve) => org.id === paramId) &&
					paramId)
			) {
				OrganizationsService.switchOrganization(paramId);
			} else {
				toggleJoinOrRedirectOrganizationModal(true, paramId, `${locationState?.orgName}`, true);
			}
		}
		setLoading(false);
	}, [params.orgId]);

	if (loading) {
		return <div></div>;
	}

	return (
		<>
			{error ? (
				<></>
			) : (
				<Routes>
					<Route
						path={'sites'}
						element={
							<ErrorBoundary
								key={1}
								FallbackComponent={(err) => (
									<SomethingWentWrong
										error={{ error: err.error, resetErrorBoundary: err.resetErrorBoundary }}
										tryAgain={err.resetErrorBoundary}
									/>
								)}>
								<Suspense fallback={<CircularLoadingFullHeight />}>
									<SitesPage isCloudProcessing={false} />
								</Suspense>
							</ErrorBoundary>
						}
					/>
					<Route
						path={'sites/:siteId'}
						element={
							<ErrorBoundary
								key={2}
								FallbackComponent={(err) => (
									<SomethingWentWrong
										error={{ error: err.error, resetErrorBoundary: err.resetErrorBoundary }}
										tryAgain={err.resetErrorBoundary}
									/>
								)}>
								<Suspense fallback={<CircularLoadingFullHeight />}>
									<SiteDetailsPage />
								</Suspense>
							</ErrorBoundary>
						}
					/>
					<Route
						path={'measurements'}
						element={
							<ErrorBoundary
								key={3}
								FallbackComponent={(err) => (
									<SomethingWentWrong
										error={{ error: err.error, resetErrorBoundary: err.resetErrorBoundary }}
										tryAgain={err.resetErrorBoundary}
									/>
								)}>
								<Suspense fallback={<CircularLoadingFullHeight />}>
									<MeasurementsPage />
								</Suspense>
							</ErrorBoundary>
						}
					/>
					<Route
						path={'measurements/:siteId/:stationId'}
						element={
							<ErrorBoundary
								key={4}
								FallbackComponent={(err) => (
									<SomethingWentWrong
										error={{ error: err.error, resetErrorBoundary: err.resetErrorBoundary }}
										tryAgain={err.resetErrorBoundary}
									/>
								)}>
								<Suspense fallback={<CircularLoadingFullHeight />}>
									<MeasurementsPage />
								</Suspense>
							</ErrorBoundary>
						}
					/>
					<Route
						path={'users'}
						element={
							<ErrorBoundary
								key={5}
								FallbackComponent={(err) => (
									<SomethingWentWrong
										error={{ error: err.error, resetErrorBoundary: err.resetErrorBoundary }}
										tryAgain={err.resetErrorBoundary}
									/>
								)}>
								<Suspense fallback={<CircularLoadingFullHeight />}>
									<UsersPage />
								</Suspense>
							</ErrorBoundary>
						}
					/>
					<Route
						path={'users/:userId'}
						element={
							<ErrorBoundary
								key={6}
								FallbackComponent={(err) => (
									<SomethingWentWrong
										error={{ error: err.error, resetErrorBoundary: err.resetErrorBoundary }}
										tryAgain={err.resetErrorBoundary}
									/>
								)}>
								<Suspense fallback={<CircularLoadingFullHeight />}>
									<ProfilePage />
								</Suspense>
							</ErrorBoundary>
						}
					/>
					<Route
						path={'map'}
						element={
							<Suspense fallback={<CircularLoadingFullHeight />}>
								<MapPage />
							</Suspense>
						}
					/>
					<Route
						path={'organization'}
						element={
							<Suspense fallback={<CircularLoadingFullHeight />}>
								<OrganizationPage />
							</Suspense>
						}
					/>
					<Route
						path={'alarms'}
						element={
							<Suspense fallback={<CircularLoadingFullHeight />}>
								<Alarms />
							</Suspense>
						}
					/>
					<Route
						path={'cloudprocessing'}
						element={
							<ProtectedRoute isAllowed={!!user?.super_admin}>
								<Suspense fallback={<CircularLoadingFullHeight />}>
									<CloudProcessingHome />
								</Suspense>
							</ProtectedRoute>
						}
					/>
					<Route
						path={'cloudprocessing/:siteId'}
						element={
							<ProtectedRoute isAllowed={!!user?.super_admin}>
								<Suspense fallback={<CircularLoadingFullHeight />}>
									<CloudProcessing />
								</Suspense>
							</ProtectedRoute>
						}
					/>
					<Route
						path={'cloudprocessing/:siteId/:stationId/:measurementId'}
						element={
							<ProtectedRoute isAllowed={!!user?.super_admin}>
								<Suspense fallback={<CircularLoadingFullHeight />}>
									<CloudProcessing />
								</Suspense>
							</ProtectedRoute>
						}
					/>
					<Route path={'*'} element={<Navigate to={`map`} />} />
				</Routes>
			)}
		</>
	);
};

export default OrganizationRouter;
