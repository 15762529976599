import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import CrossSectionInput from 'components/CrossSection/CrossSectionInput';
import CrossSectionPreview from 'components/CrossSection/CrossSectionPreview';
import Modal from 'components/shared/Modal/Modal';
import { TabPanel } from 'components/shared/TabPanel/TabPanel';
import { useGlobalStore } from 'global-state/useStore';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const SetUpCrossSectionModal = () => {
	const { t } = useTranslation();

	const setUpCrossSectionModal = useGlobalStore((state) => state.setUpCrossSectionModal);
	const toggleSetUpCrossSectionModal = useGlobalStore(
		(state) => state.toggleSetUpCrossSectionModal
	);
	const profileCalibration = useGlobalStore((state) => state.profileCalibration);
	const setCalibrationImg = useGlobalStore((state) => state.setCalibrationImg);

	const handleClose = () => {
		setCalibrationImg('', [], []);
		toggleSetUpCrossSectionModal({
			open: false,
			station_id: null
		});
	};
	const [tabValue, setTabValue] = useState<number>(0);
	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setTabValue(newValue);
	};

	useEffect(() => {
		profileCalibration.base64Img !== '' && setTabValue(1);
	}, [profileCalibration]);

	return (
		<Modal
			closeModal={handleClose}
			modalTitle={t('SET_UP_CROSS_SECTION')}
			open={setUpCrossSectionModal.open}>
			<div>
				<Tabs
					className="w-full max-w-3xl"
					variant="scrollable"
					allowScrollButtonsMobile
					value={tabValue}
					onChange={handleTabChange}>
					<Tab label={t('INPUT')} />
					<Tab label={t('PREVIEW')} disabled={profileCalibration.base64Img === ''} />
				</Tabs>
				<div className="w-full max-w-3xl">
					<TabPanel value={tabValue} index={0}>
						<CrossSectionInput />
					</TabPanel>{' '}
					<TabPanel value={tabValue} index={1}>
						<CrossSectionPreview />
					</TabPanel>
				</div>
			</div>
		</Modal>
	);
};
export default SetUpCrossSectionModal;
