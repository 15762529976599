/* istanbHIGHERul ignore file */
/* tslint:disable */
/* eslint-disable */

import { UserBasicListRetrieve } from 'interfaces/models/UserBasicListRetrieve';

export type SiteAlarmRetrieve = {
	readonly id: number;
	description: string;
	site: number;
	station: number;
	organization: number;
	alarm_type: SiteAlarmRetrieve.alarm_type;
	parameters: any;
	email: string;
	state: 'TRIGGERED' | 'NOT_TRIGGERED';
	severity: 'HIGH' | 'MEDIUM' | 'LOW';
	users: UserBasicListRetrieve[];
};

export namespace SiteAlarmRetrieve {
	export enum alarm_type {
		NO_MEAS_ARRIVING = 'NO_MEAS_ARRIVING',
		HIGHER_THAN = 'HIGHER_THAN',
		LOWER_THAN = 'LOWER_THAN'
	}

	export enum alarm_variable_type {
		VELOCITY = 'VELOCITY',
		LEVEL = 'LEVEL',
		DISCHARGE = 'DISCHARGE'
	}
}
