import ReactJson, { InteractionProps } from '@microlink/react-json-view';
import CircularProgress from '@mui/material/CircularProgress';
import PrimaryButton from 'components/shared/Button/PrimaryButton';
import Modal from 'components/shared/Modal/Modal';
import { useFormik } from 'formik';
import { useGlobalStore } from 'global-state/useStore';
import {
	onAdvancedConfigItemAdd,
	onAdvancedConfigItemDelete,
	validateAdvancedConfigAdd,
	validateAdvancedConfigDelete,
	validateAdvancedConfigEdit
} from 'helper/helperFunctions';
import { JsonData } from 'helper/types';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import StationService from 'services/StationService/StationService';

const EditAdvancedConfigModal = () => {
	const { t } = useTranslation();
	const editAdvancedConfig = useGlobalStore((state) => state.editAdvancedConfig);
	const toggleEditAdvancedConfig = useGlobalStore((state) => state.toggleEditAdvancedConfig);
	const selectedSiteId = useGlobalStore((state) => state.selectedSiteId);

	const [initialValuesState, setInitialValuesState] = useState<JsonData | null>(null);
	const [fieldsData, setFieldsData] = useState<JsonData | null>(null);
	const [validationSchema, setValidationSchema] = useState<JsonData | null>(null);
	const [loadingJson] = useState(false);
	const [loadingAdvancedConfigList, setLoadingAdvancedConfigList] = useState(true);

	const handleClose = () => {
		toggleEditAdvancedConfig(false, null);
	};

	useEffect(() => {
		StationService.getAdvancedConfig(
			Number(selectedSiteId),
			Number(editAdvancedConfig.station_id)
		).then((res) => {
			setLoadingAdvancedConfigList(false);
			setFieldsData(res.data_types);
			setValidationSchema(res.data_types);
			setInitialValuesState(res.advanced_configuration);
		});
	}, []);

	const formik = useFormik({
		initialValues: initialValuesState ?? {},
		onSubmit: (data) => {
			StationService.uploadAdvancedConfig(
				Number(selectedSiteId),
				Number(editAdvancedConfig.station_id),
				data
			).then(() => {
				toast.success(t('CONFIG_UPDATED'));
				toggleEditAdvancedConfig(false, null);
			});
		},
		enableReinitialize: true
	});

	return (
		<Modal
			open={editAdvancedConfig.open}
			closeModal={handleClose}
			modalTitle={t('ADVANCED_CONFIGURATION')}>
			<div className={'flex w-[70rem] flex-col items-center justify-center gap-4 p-4'}>
				{loadingJson || loadingAdvancedConfigList ? (
					<CircularProgress />
				) : (
					<>
						<div className={' w-full'}>
							<form
								className="flex max-h-[40rem] w-full flex-col gap-6 overflow-scroll"
								onSubmit={formik.handleSubmit}
								autoComplete="off">
								<ReactJson
									src={initialValuesState ? initialValuesState : fieldsData ?? {}}
									onEdit={(edit: InteractionProps) => {
										if (validateAdvancedConfigEdit(edit, validationSchema)) {
											setInitialValuesState(edit.updated_src as JsonData);
										} else return false;
									}}
									onAdd={(add: InteractionProps) => {
										if (validateAdvancedConfigAdd(add)) {
											onAdvancedConfigItemAdd(add, fieldsData, setInitialValuesState);
										} else return false;
									}}
									onDelete={(del: InteractionProps) => {
										if (validateAdvancedConfigDelete(del)) {
											onAdvancedConfigItemDelete(del, setInitialValuesState);
										} else {
											toast.error(t('PROP_DELETE_NOT_ALLOWED', del.name ?? ''));
											return false;
										}
									}}
								/>
							</form>
						</div>
						<PrimaryButton
							type="submit"
							onClick={formik.handleSubmit}
							className={'self-center normal-case'}>
							{t('UPDATE')}
						</PrimaryButton>
					</>
				)}
			</div>
		</Modal>
	);
};
export default EditAdvancedConfigModal;
