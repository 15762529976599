import { ICrossSectionFreeProfileChartProps } from 'components/CrossSection/types';
import React, { FunctionComponent, useMemo } from 'react';
import { Chart } from 'react-chartjs-2';
import {
	calculateGroundGraphData,
	prepareFreeProfileDataForVisualization
} from 'utils/freeProfileParamsHelper';
import {
	CategoryScale,
	Chart as ChartJS,
	Filler,
	Legend,
	LinearScale,
	LineController,
	LineElement,
	PointElement,
	ScatterController,
	TimeScale,
	Title,
	Tooltip
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { useStationDetails } from '../../rq/hooks/stationHook';
import { DischargeStationDetails } from '../../interfaces/models/DischargeStationDetails';
import { DischargeKeeperStationDetails } from '../../interfaces/models/DischargeKeeperStationDetails';
import useStationDataFromStore from '../../hooks/useStationDataFromStore';
import { useGlobalStore } from '../../global-state/useStore';

ChartJS.register(
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	TimeScale,
	CategoryScale,
	Filler,
	zoomPlugin,
	ScatterController,
	LineController
);
const CrossSectionFreeProfileChart: FunctionComponent<ICrossSectionFreeProfileChartProps> = () => {
	const { station_id } = useGlobalStore((state) => state.freeProfileChartModal);
	const selectedSiteId = useGlobalStore((state) => state.selectedSiteId);
	const { data: stationDetails } = useStationDetails<
		DischargeStationDetails | DischargeKeeperStationDetails
	>(Number(selectedSiteId), Number(station_id), { enabled: !!selectedSiteId && !!station_id });

	const { stationData } = useStationDataFromStore(stationDetails);
	const data = useMemo(
		() =>
			prepareFreeProfileDataForVisualization(
				(stationData as DischargeStationDetails)?.profile.free_params
			),
		[stationData, stationDetails]
	);

	const chartData = useMemo(
		() => ({
			datasets: [
				{
					type: 'scatter' as const,
					label: 'Area Dataset',

					data: calculateGroundGraphData(data ?? []),
					pointBackgroundColor: 'black',
					pointRadius: 3,
					order: 1
				},
				{
					type: 'line' as const,
					label: 'Area Dataset',
					transparent: true,
					data: calculateGroundGraphData(data ?? []),
					pointBackgroundColor: '#bfbdb8',
					borderColor: '#bfbdb8',

					pointRadius: 3,
					order: 1,
					fill: '+1'
				},
				{
					type: 'line' as const,
					pointBackgroundColor: 'blue',
					borderColor: 'blue',
					order: 2,
					data: data,
					pointRadius: 1,
					hoverRadius: 5,
					backgroundColor: '#bfbdb8',
					fill: '+1'
				}
			]
		}),
		[data]
	);

	const options = useMemo(
		() => ({
			responsive: true,
			maintainAspectRatio: false,
			interaction: {
				mode: 'nearest' as const,
				intersect: false
			},
			plugins: {
				legend: { display: false },
				tooltip: {
					callbacks: {
						label: function (context: any) {
							return `y: ${context.parsed.x} m, z: ${context.parsed.y} m`;
						}
					}
				}
			},
			scales: {
				x: {
					title: { display: false },
					ticks: { display: false },
					grid: { display: false }
				},
				y: {
					title: { display: false },
					ticks: { display: false },
					grid: { display: false }
				}
			},
			parsing: {
				xAxisKey: 'x',
				yAxisKey: 'y'
			}
		}),
		[chartData, data]
	);
	return <Chart type={'scatter'} data={chartData} options={options} />;
};
export default CrossSectionFreeProfileChart;
