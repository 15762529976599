import axios from 'axios';
import { format, intervalToDuration } from 'date-fns';
import { getScalingCoeffForImg } from 'helper/helperFunctions';
import { StationMeasurementsModel } from 'interfaces/models/StationMeasurementsModel';
import { UserListRetrieve } from 'interfaces/models/UserListRetrieve';
import { ChangeEvent, RefObject } from 'react';
import { queryKeys } from 'rq/constants';
import {
	MembershipPermissionCreateUpdate,
	OrganizationListRetrieve
} from 'rq/interfaces/Organization';
import { SiteModel } from 'rq/interfaces/Sites';
import { queryClient } from 'rq/queryClient';
import i18n from 'res/localization/i18n';
import { ProcessingResultsData } from 'services/CloudProcessingService/types';
import { convertDataToVectors, drawVectors, drawWaterLine } from 'utils/canvasFunctions';
import { MeasurementFilter, ParsedFilter } from 'utils/helperData';
import { prepareData } from './freeProfileParamsHelper';
import { scientificNotationNumberPattern } from './regex';

export const displayUserFullName = (user: UserListRetrieve | null | undefined) => {
	if (!user) return '';
	if (user.first_name?.trim() === '' && user.last_name?.trim() === '') {
		return user.username;
	} else {
		return user.first_name + ' ' + user.last_name;
	}
};

export const checkOrgIdAndPermissions = (user: UserListRetrieve, userId: number | undefined) => {
	let organizationId =
		localStorage.getItem(`lastOrganization-${user.id}`) ??
		(user.organizations && user.organizations.length !== 0
			? user.organizations.some((org) => org.id === 1)
				? 1
				: user.organizations[0].id
			: null);

	if (
		user.organizations &&
		user.organizations.length !== 0 &&
		!user.super_admin &&
		!user.organizations.some((org: OrganizationListRetrieve) => org.id === Number(organizationId))
	) {
		organizationId = user.organizations[0].id;
	}

	if (user.organizations && user.organizations.length === 0) {
		localStorage.removeItem(`lastOrganization-${user.id}`);
		localStorage.removeItem('currentOrganizationId');
		organizationId = null;
	}

	if (organizationId) {
		localStorage.setItem('currentOrganizationId', `${organizationId}`);
		localStorage.setItem(`lastOrganization-${userId}`, `${organizationId}`);
	}

	const organization =
		user.organizations?.find(
			(org: OrganizationListRetrieve) => org.id === Number(organizationId)
		) ?? queryClient.getQueryData([queryKeys.organization, organizationId]);

	const permissions: MembershipPermissionCreateUpdate | null = organization?.permissions ?? null;
	const name: string | null = organization?.name ?? null;

	return { id: Number(organizationId), permissions: permissions, name: name };
};

export const checkOrgPermissionsForUser = (user: UserListRetrieve | null, orgId: number) => {
	const id = orgId;
	const organization = queryClient.getQueryData([
		queryKeys.organization,
		orgId
	]) as OrganizationListRetrieve;
	const permissions = organization?.permissions ?? null;
	const name = organization?.name ?? null;
	return { id: id, permissions: permissions, name: name };
};

export const humanReadableDateFormat = ({
	date,
	absoluteTime
}: {
	absoluteTime?: boolean;
	date: Date;
}) => {
	if (absoluteTime) return format(new Date(date), 'MMMM dd, yyyy hh:mm a');
	const { years, months, weeks, days, hours, minutes, seconds } = intervalToDuration({
		start: new Date(date),
		end: new Date(Date.now())
	});

	let returnString = '';
	if (years) returnString += `${years} ${years > 1 ? i18n.t('YEARS') : i18n.t('YEAR')} `;
	if (months) returnString += `${months} ${months > 1 ? i18n.t('MONTHS') : i18n.t('MONTH')} `;
	if (weeks && weeks > 0 && !years)
		returnString += `${weeks} ${weeks > 1 ? i18n.t('WEEKS') : i18n.t('WEEKS')} `;
	if (days && !years) returnString += `${days} ${days > 1 ? i18n.t('DAYS') : i18n.t('DAY')} `;
	if (hours && !years && !months)
		returnString += `${hours} ${hours > 1 ? i18n.t('HOURS') : i18n.t('HOUR')} `;
	if (minutes && !years && !months && !weeks && !days)
		returnString += `${minutes} ${minutes > 1 ? i18n.t('MINUTES') : i18n.t('MINUTE')} `;
	if (seconds && !years && !months && !weeks && !days)
		returnString += `${seconds} ${seconds > 1 ? i18n.t('SECONDS') : i18n.t('SECOND')} `;

	return returnString;
};
export const getQueryParamsString = (
	queryParams:
		| {
				[key: string]: string | number | boolean;
		  }
		| undefined
) => {
	let queryString = '';
	if (!queryParams) return queryString;
	Object.keys(queryParams).forEach((key) => {
		type ObjectKey = keyof typeof queryParams;
		const myKey = `${key}` as ObjectKey;
		if (queryParams && queryParams[myKey]) {
			queryString += `${myKey}=${queryParams[myKey]}&`;
		}
	});
	return queryString;
};

export const parseMeasurementFilters = (filters: MeasurementFilter[]): ParsedFilter[] => {
	const validFilters: ParsedFilter[] = [];
	const filterNameMap = new Map<string, Set<string>>();

	for (const filterData of filters) {
		const { filterName, operator, filterValue } = filterData;
		const key = filterName !== 'username' ? `${filterName}__${operator.value}` : 'username';
		const filterSet = filterNameMap.get(filterName) || new Set();
		if (operator.value === 'gt' && filterSet.has('gt')) {
			console.log(
				`Filter for ${filterName} with ${
					operator.value === 'gt' ? 'greater than' : 'less than'
				} already exists. Skipping this filter.`
			);
			continue;
		}

		filterSet.add(operator.value);
		filterNameMap.set(filterName, filterSet);

		validFilters.push({
			filter: key,
			value: filterValue
		});
	}
	return validFilters;
};

export const parseFilterValue = (filters: ParsedFilter[], filterName: string) => {
	return filters.find((filter) => filter.filter === filterName)?.value.toString();
};

export const generateMeasurementFilterQueryParams = (parsedFilters: ParsedFilter[] | undefined) => {
	return {
		discharge__gt: parsedFilters && parseFilterValue(parsedFilters, 'discharge__gt'),
		discharge__lt: parsedFilters && parseFilterValue(parsedFilters, 'discharge__lt'),
		discharge__eq: parsedFilters && parseFilterValue(parsedFilters, 'discharge__eq'),
		discharge__le: parsedFilters && parseFilterValue(parsedFilters, 'discharge__le'),
		discharge__ge: parsedFilters && parseFilterValue(parsedFilters, 'discharge__ge'),
		level__gt: parsedFilters && parseFilterValue(parsedFilters, 'level__gt'),
		level__lt: parsedFilters && parseFilterValue(parsedFilters, 'level__lt'),
		level__eq: parsedFilters && parseFilterValue(parsedFilters, 'level__eq'),
		level__le: parsedFilters && parseFilterValue(parsedFilters, 'level__le'),
		level__ge: parsedFilters && parseFilterValue(parsedFilters, 'level__ge'),
		velocity__gt: parsedFilters && parseFilterValue(parsedFilters, 'velocity__gt'),
		velocity__lt: parsedFilters && parseFilterValue(parsedFilters, 'velocity__lt'),
		velocity__eq: parsedFilters && parseFilterValue(parsedFilters, 'velocity__eq'),
		velocity__le: parsedFilters && parseFilterValue(parsedFilters, 'velocity__le'),
		velocity__ge: parsedFilters && parseFilterValue(parsedFilters, 'velocity__ge'),
		bulk_velocity__gt: parsedFilters && parseFilterValue(parsedFilters, 'bulk_velocity__gt'),
		bulk_velocity__lt: parsedFilters && parseFilterValue(parsedFilters, 'bulk_velocity__lt'),
		bulk_velocity__eq: parsedFilters && parseFilterValue(parsedFilters, 'bulk_velocity__eq'),
		bulk_velocity__le: parsedFilters && parseFilterValue(parsedFilters, 'bulk_velocity__le'),
		bulk_velocity__ge: parsedFilters && parseFilterValue(parsedFilters, 'bulk_velocity__ge'),
		displacement__gt: parsedFilters && parseFilterValue(parsedFilters, 'displacement__gt'),
		displacement__lt: parsedFilters && parseFilterValue(parsedFilters, 'displacement__lt'),
		displacement__eq: parsedFilters && parseFilterValue(parsedFilters, 'displacement__eq'),
		displacement__le: parsedFilters && parseFilterValue(parsedFilters, 'displacement__le'),
		displacement__ge: parsedFilters && parseFilterValue(parsedFilters, 'displacement__ge'),
		mean_surface_velocity__gt:
			parsedFilters && parseFilterValue(parsedFilters, 'mean_surface_velocity__gt'),
		mean_surface_velocity__lt:
			parsedFilters && parseFilterValue(parsedFilters, 'mean_surface_velocity__lt'),
		mean_surface_velocity__eq:
			parsedFilters && parseFilterValue(parsedFilters, 'mean_surface_velocity__eq'),
		mean_surface_velocity__le:
			parsedFilters && parseFilterValue(parsedFilters, 'mean_surface_velocity__le'),
		mean_surface_velocity__ge:
			parsedFilters && parseFilterValue(parsedFilters, 'mean_surface_velocity__ge'),
		wet_cross_sectional_area__gt:
			parsedFilters && parseFilterValue(parsedFilters, 'wet_cross_sectional_area__gt'),
		wet_cross_sectional_area__lt:
			parsedFilters && parseFilterValue(parsedFilters, 'wet_cross_sectional_area__lt'),
		wet_cross_sectional_area__eq:
			parsedFilters && parseFilterValue(parsedFilters, 'wet_cross_sectional_area__eq'),
		wet_cross_sectional_area__le:
			parsedFilters && parseFilterValue(parsedFilters, 'wet_cross_sectional_area__le'),
		wet_cross_sectional_area__ge:
			parsedFilters && parseFilterValue(parsedFilters, 'wet_cross_sectional_area__ge'),
		username: parsedFilters && parseFilterValue(parsedFilters, 'username'),
		closest_to_level: parsedFilters && parseFilterValue(parsedFilters, 'closest_to_level'),
		closest_to_discharge: parsedFilters && parseFilterValue(parsedFilters, 'closest_to_discharge'),
		closest_to_velocity: parsedFilters && parseFilterValue(parsedFilters, 'closest_to_velocity'),
		closest_to_hour: parsedFilters && parseFilterValue(parsedFilters, 'closest_to_hour'),
		closest_to_date: parsedFilters && parseFilterValue(parsedFilters, 'closest_to_date')
	};
};
export const isMarkersCountValid = (markers: number[] | string[]) => {
	return (
		markers.length === 6 ||
		(markers.length >= 12 && markers.length <= 60 && markers.length % 3 === 0)
	);
};

const determineErrorMessage = (count: number) => {
	let errorMessage;

	if (count < 6) {
		errorMessage = `${i18n.t('MIN_NUMBER_OF_VALUES')} 6`;
	} else if (count > 6 && count < 12) {
		errorMessage = `${i18n.t('VALUES_NOT_A_NUMBER_BETWEEN')} 6 ${i18n.t('AND')} 12`;
	} else if (count > 60) {
		errorMessage = `${i18n.t('MAX_NUMBER_OF_VALUES')} 60`;
	} else if (count % 3 !== 0) {
		errorMessage = `${i18n.t('VALUES_DIVISIBLE_BY')} 3`;
	} else {
		errorMessage = i18n.t('INVALID_INPUT_DATA');
	}
	return errorMessage;
};

export const validateMarkerCoordinates = (
	markersCoordinates: string | undefined
): {
	errorMessage: string;
	isValid: boolean;
} => {
	if (!markersCoordinates) {
		return { errorMessage: i18n.t('NO_MARKERS_DEFINED'), isValid: false };
	}
	const markersCoordinatesArray: string[] = markersCoordinates.split(',').map(String);
	for (const coord of markersCoordinatesArray) {
		if (!isValidCoordinate(coord) || coord === '') {
			return { errorMessage: i18n.t('INVALID_INPUT_DATA'), isValid: false };
		}
	}

	if (!isMarkersCountValid(markersCoordinatesArray)) {
		return { errorMessage: determineErrorMessage(markersCoordinatesArray.length), isValid: false };
	}

	return { errorMessage: '', isValid: true };
};

const isValidCoordinate = (coord: string): boolean => {
	return !coord.match(/[^(,)|(.)|(\-)|(0-9)]/g);
};

export const createGCPFormatArray = (gcpData: string) => {
	const rearrangedArray = [];
	const xValues = [];
	const yValues = [];
	const zValues = [];
	const coordinates = prepareData(
		gcpData.match(scientificNotationNumberPattern)?.join(',') ?? ''
	).split(',');

	for (let i = 0; i < coordinates.length; i += 3) {
		xValues.push(coordinates[i]);
		yValues.push(coordinates[i + 1]);
		zValues.push(coordinates[i + 2]);
	}

	rearrangedArray.push(...xValues, ...yValues, ...zValues);
	return rearrangedArray;
};

export const handleFileReadAsString = async (
	event: ChangeEvent<HTMLInputElement>,
	readValueHandler: (content: string) => void
) => {
	const file = event.target.files?.[0];
	if (file) {
		const reader = new FileReader();
		reader.onload = (e) => {
			const content = e.target?.result;
			readValueHandler((content as string).trim());
		};
		reader.readAsText(file);
	}
};
export const handleFileRead = async (
	event: ChangeEvent<HTMLInputElement>,
	readValueHandler: (fileName: string, content: string, inputAlias: string) => void,
	inputAlias: string
) => {
	const file = event.target.files?.[0];
	if (file) {
		const reader = new FileReader();
		reader.onload = (e) => {
			const content = e.target?.result;
			readValueHandler(file.name, (content as string).trim(), inputAlias);
		};
		reader.readAsText(file);
	}
};

export const handleFileReadAsJSON = async (
	event: ChangeEvent<HTMLInputElement>,
	readValueHandler: (content: any) => void
) => {
	const file = event.target.files?.[0];
	if (file) {
		const reader = new FileReader();
		reader.onload = (e) => {
			const content = e.target?.result;
			try {
				const json = JSON.parse(content as string);
				readValueHandler(json);
			} catch (error) {
				readValueHandler(null);
			}
		};
		reader.readAsText(file);
	}
};

export const handleCanvasDrawing = (
	canvasRef: RefObject<HTMLCanvasElement>,
	imgRef: RefObject<HTMLImageElement>,
	results: StationMeasurementsModel | ProcessingResultsData,
	img: any,
	isStiv = false
) => {
	const ctx = canvasRef.current?.getContext('2d');
	if (!ctx) return;
	ctx.canvas.width = imgRef.current?.width ?? 0;
	ctx.canvas.height = imgRef.current?.height ?? 0;

	const { imgScaleX, imgScaleY } = getScalingCoeffForImg(`${img}`, imgRef);

	//clear if redrawing a result
	imgRef.current?.width &&
		imgRef.current?.height &&
		ctx.clearRect(0, 0, imgRef.current?.width, imgRef.current?.height);

	const waterLine = results.level_geometry;

	!!waterLine && drawWaterLine(waterLine, ctx, imgScaleX, imgScaleY);
	const vectorData =
		!!results.image_displacement_field && convertDataToVectors(results.image_displacement_field);
	!!vectorData && drawVectors(vectorData, ctx, imgScaleX, imgScaleY, isStiv);
};

/*
export const getScalingCoeffForImg = (
	originalImgSrc: string,
	scaledImageRef: RefObject<HTMLImageElement>
) => {
	const img = new Image();
	img.src = `${originalImgSrc}`;
	let imgScaleX = 1;
	let imgScaleY = 1;

	if (scaledImageRef && img.naturalWidth !== Number(scaledImageRef.current?.width)) {
		imgScaleX = img.naturalWidth / Number(scaledImageRef.current?.width);
		imgScaleY = img.naturalHeight / Number(scaledImageRef.current?.height);
	}

	return {
		imgScaleX,
		imgScaleY
	};
};
*/

export const fetchJsonFromUrl = async (url: string) => {
	return await axios.get(url).then((res) => {
		return res.data;
	});
};

export const fetchCustomConfigFromUrl = async (url: string) => {
	return await axios
		.get(url, {
			headers: {
				'Cache-Control': 'no-cache, no-store, must-revalidate',
				Pragma: 'no-cache',
				Expires: '0'
			}
		})
		.then((res) => {
			return res.data;
		});
};

export const getNumericAnyParams = (siteData: SiteModel | undefined) => {
	if (!siteData || !siteData.any_params) return [];
	else return siteData.any_params.filter((p) => p.type === 'NUMBER');
};

export const findAnyMeasurementsByParamId = (
	measurement: StationMeasurementsModel,
	paramId: number,
	paramName: string
) => {
	if (!measurement.any_measurements) return null;

	return {
		id: Math.random(),
		date_time: measurement.date_time,
		paramName: paramName,
		utcOffset: measurement.utc_offset,
		...measurement.any_measurements?.find((meas) => meas.site_any_param_id === paramId)
	};
};
export const hasValueProperty = (
	obj: any
): obj is {
	name: string;
	value: number;
	displayValue: string;
	group: string;
} => {
	return obj && typeof obj === 'object' && 'value' in obj;
};
