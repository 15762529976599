import { useMutation, UseQueryOptions } from 'react-query';
import { SiteAlarmCreateDestroy } from 'interfaces/models/SiteAlarmCreateDestroy';
import { SiteAlarmRetrieve } from 'interfaces/models/SiteAlarmRetrieve';
import { UseMutationOptions, useQuery } from 'react-query';
import { queryKeys } from 'rq/constants';
import AlarmsService from 'services/AlarmsService/AlarmsService';

export const useAlarms = (
	orgId: string,
	options?: Omit<
		UseQueryOptions<SiteAlarmRetrieve[], unknown, SiteAlarmRetrieve[], any>,
		'queryKey' | 'queryFn'
	>
) => {
	return useQuery<SiteAlarmRetrieve[]>(
		[queryKeys.alarms, `org-id-${orgId}`],
		() => AlarmsService.alarmsRead(),
		{ ...options }
	);
};

export function useCreateAlarm(
	options?: Omit<
		UseMutationOptions<SiteAlarmCreateDestroy, unknown, unknown, unknown>,
		'mutationFn'
	>
) {
	return useMutation((data: SiteAlarmCreateDestroy) => AlarmsService.alarmCreate(data), {
		...options
	});
}

export function useUpdateAlarm(
	options?: Omit<
		UseMutationOptions<SiteAlarmCreateDestroy, unknown, unknown, unknown>,
		'mutationFn'
	>
) {
	return useMutation(
		({ alarmId, data }: { alarmId: string; data: SiteAlarmCreateDestroy }) =>
			AlarmsService.alarmUpdate(alarmId, data),
		{
			...options
		}
	);
}

export function useDeleteAlarm(
	options?: Omit<UseMutationOptions<void, unknown, unknown, unknown>, 'mutationFn'>
) {
	return useMutation((alarmId: string) => AlarmsService.alarmDelete(alarmId), {
		...options
	});
}
